<sc-page-content identifier="lozenges">

	<ng-container editorInputs>
		<uf-radio [columns]="3" [options]="colourOptions" [(value)]="colour" (valueChange)="modifierChange()"
			class="col-8" label="Colour" valueProperty="value" />
		<uf-radio [columns]="1" [options]="sizeOptions" [(value)]="size" (valueChange)="modifierChange()" class="col-3"
			label="Sizes" valueProperty="value" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template">
			<div [ngClass]="[colour,size]" [innerHTML]="colour || 'default'" [title]="colour || 'default'"
				class="uf-lozenge">
			</div>
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>