export enum FormDefinitionMetadataIdentifiers {
	Id = 'id',
	DefinitionIdentifier = '_definitionIdentifier',
	DefinitionVersion = '_definitionVersion',
	OpenedAt = '_openedAt',
	CompletedAt = '_completedAt',
	StoredAt = '_storedAt',
	State = '_state',
	Action = '_action',
	Result = '_result',
	History = '_history',
	Bucket = '_bucket',
	Seq = '_seq',
	SeqId = '_seqId',
	CreatedAt = '_createdAt',
	CreatedBy = '_createdBy',
	LastModifiedAt = '_lastModifiedAt',
	LastModifiedBy = '_lastModifiedBy',
	Origin = '_origin',
	Rev = '_rev',
	Parent = '_parent',
	ParentId = '_parent.id',
	ParentSeqId = '_parent.seqId',
	ParentBucket = '_parent.bucket',
	ParentDefinitionIdentifier = '_parent.definitionIdentifier',
	ParentDefinitionVersion = '_parent._definitionVersion',
}

export enum CollectionItemMetadataIdentifiers {
	Id = 'id',
	LastModifiedAt = 'lastModifiedAt',
	LastPublishedAt = 'lastPublishedAt',
	DefinitionIdentifier = '_definitionIdentifier',
	PublishedVersion = '_publishedVersion',
	Rev = '_rev',
	State = '_state',
	Title = '_title',
}

// TODO Why 'isActive' and 'hasPassword' are not in this list?
export enum UserInfoIdentifiers {
	Id = 'id',
	Username = 'username',
	FirstName = 'firstName',
	LastName = 'lastName',
	Email = 'email',
	Phone = 'phone',
	Claims = 'claims',
	Company = 'company',
	CompanyId = 'company.id',
	CompanyName = 'company.name',
	Roles = 'roles',
	CreatedAt = 'createdAt',
	LastModifiedAt = 'lastModifiedAt',
	IsExternal = 'isExternal',
	Units = 'units',
	UnitPaths = 'unitPaths',
	Manager = 'manager',
	ManagerId = 'manager.id',
	ManagerUsername = 'manager.username',
	ManagerFirstName = 'manager.firstName',
	ManagerLastName = 'manager.lastName',
	ManagerEmail = 'manager.email',
	ManagerPhone = 'manager.phone',
}

export enum StepFieldMetadataIdentifiers {
	LastViewedAt = '_lastViewedAt',
	LastCompletedAt = '_lastCompletedAt',
}

export enum CompanyIdentifiers {
	Id = 'id',
	Name = 'name',
	Status = 'status',
	Claims = 'claims'
}
