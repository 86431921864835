<sc-page-content identifier="avatars">

	<ng-container editorInputs>
		<uf-radio [columns]="1" [options]="sizeOptions" [(value)]="size" (valueChange)="modifierChange()" class="col-3"
			label="Sizes" valueProperty="value" />
		<uf-radio [columns]="2" [options]="colourOptions" [(value)]="colour" (valueChange)="modifierChange()"
			class="col-6" label="Colour" valueProperty="value" />
		<uf-radio [columns]="1" [options]="contentOptions" [(value)]="content" (valueChange)="contentChange()"
			class="col-3" label="Content" valueProperty="value" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander>
			<div [ngClass]="[colour, size]" class="uf-avatar">
				<ng-container [ngSwitch]="content">
					<img *ngSwitchCase="'image'" src="../../../assets/img/simon_avatar.png">
					<uf-icon *ngSwitchCase="'icon'"
						[name]="colour !== 'disabled' && colour !== 'accent' && colour !== 'custom-colour' ? colour : 'edit'" />
					<ng-container *ngSwitchDefault>
						SD
					</ng-container>
				</ng-container>
			</div>
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>