import { Component, Inject, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Client, MeClient, ProjectInfo, TenantClient } from '@unifii/sdk';

import { ProgressComponent, StepComponent, StorageWrapper, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';

import { Config } from '../models';
import { ShowcaseRepositoryService, StorageKeys } from '../services';

const FieldMandatoryMessage = 'This field is mandatory';

@Component({
	selector: 'sc-login',
	templateUrl: './show-login.html',
})
export class ShowLoginComponent {

	@ViewChild('loginStep') protected loginStep: StepComponent<void>;
	@ViewChild(ProgressComponent) protected progress: ProgressComponent;

	protected readonly loginForm = new UfControlGroup({
		tenant: new UfControl(ValidatorFunctions.required(FieldMandatoryMessage)),
		username: new UfControl(ValidatorFunctions.required(FieldMandatoryMessage)),
		password: new UfControl(ValidatorFunctions.required(FieldMandatoryMessage)),
	});

	protected readonly projectControl = new UfControl(ValidatorFunctions.required(FieldMandatoryMessage));

	protected username: string;
	protected password: string;
	protected projects: ProjectInfo[] | null;
	protected projectId: string;
	protected error: string | null;

	private readonly urlPrefix = 'https://';
	private readonly urlSuffix = '.unifii.net';

	private storage = inject(StorageWrapper);
	private isLoggedIn: boolean;

	constructor(
		@Inject(Config) public config: Config,
		public repository: ShowcaseRepositoryService,
		private router: Router,
		private client: Client,
		private tenantClient: TenantClient,
		private meClient: MeClient,
	) {

		if (this.repository.company) {
			this.loginForm.get('tenant')?.setValue(this.repository.company);
		}

		this.isLoggedIn = !!this.storage.getItem(StorageKeys.AccessToken);

		if (!this.isLoggedIn) {
			this.logout();

			return;
		}

		if (this.repository.project) {
			void this.router.navigate(['/']);
		}

	}

	async login() {
		this.repository.company = this.loginForm.get('tenant')?.value as string | null;
		this.repository.baseUrl = `${this.urlPrefix}${this.repository.company}${this.urlSuffix}`;
		this.config.unifii.baseUrl = this.repository.baseUrl;
		this.error = null;
		this.progress.start();

		try {
			await this.client.authenticate(this.username, this.password);

			const user = await this.meClient.get();

			this.repository.token = this.client.token;
			this.repository.user = user;

			const projects = await this.tenantClient.getProjects();

			this.projects = this.sortProjects(projects);
			this.loginStep.complete();

		} catch (err) {
			this.logout();
			this.error = 'Login failed';
		} finally {
			this.progress.complete();
		}
	}

	logout() {
		this.repository.token = null;
		this.repository.user = null;
		this.repository.project = null;
		this.projects = null;
	}

	selectProject(projectId: string) {
		this.progress.start();

		this.projectId = projectId;
		this.repository.project = (this.projects ?? []).find((p) => p.id === this.projectId) ?? null;
		this.repository.preview = false;

		void this.router.navigate(['/']);
	}

	projectLabel(project: ProjectInfo) {
		return project.id + ' - ' + project.name;
	}

	private sortProjects(projects: ProjectInfo[] = []): ProjectInfo[] {
		return projects.sort((a, b) => +(a.id) - (+b.id));
	}

}
