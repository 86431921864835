<sc-page-content identifier="group">

	<ng-container editorInputs>
		<uf-radio [columns]="1" [options]="styleOptions" [(value)]="style" (valueChange)="modifierChange()"
			class="col-6 col-2-md" label="Styles" valueProperty="value" />
		<uf-radio [columns]="1" [options]="appBarSizeOptions" [(value)]="appBarSize" (valueChange)="modifierChange()"
			class="col-6 col-2-md" label="App Bar Size" valueProperty="value" />
		<uf-radio [columns]="1" [options]="appBarColourOptions" [(value)]="appBarColour"
			(valueChange)="modifierChange()" class="col-6 col-2-md" label="App Bar Colour" valueProperty="value" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander>
			<div [ngClass]="[style]" class="uf-box">
				<div [ngClass]="[appBarSize, appBarColour]" class="uf-app-bar">
					<div class="title">
						My Favourite Latin
					</div>
					<a class="uf-action">
						<uf-icon name="contextual" />
					</a>
				</div>
				<div class="pad-left pad-right">
					<h3>Totam rem et quasi architecto beatae vitae dicta sunt explicabo.
					</h3>
					<p>Sed posuere consectetur est at lobortis. Cum sociis natoque penatibus et magnis dis
						parturient montes, nascetur ridiculus mus. Vestibulum id ligula porta felis euismod
						semper. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel
						augue laoreet rutrum faucibus dolor auctor</p>
					<br>
				</div>
			</div>
		</sc-code-editor-expander>

	</ng-container>

	<ng-container api>
		<h2>CSS Classes</h2>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>