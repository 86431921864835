<sc-page-content identifier="chips">

	<ng-container editorInputs>
		<uf-radio [columns]="1" [options]="sizeOptions" [(value)]="size" (valueChange)="modifierChange()"
			class="col-6 col-2-md" label="Sizes" valueProperty="value" />
		<uf-radio [columns]="2" [options]="colourOptions" [(value)]="colour" (valueChange)="modifierChange()"
			class="col-6 col-3-md" label="Colour" valueProperty="value" />
		<uf-radio [columns]="1" [options]="typeOptions" [(value)]="type" (valueChange)="modifierChange()"
			class="col-6 col-2-md" label="Type" valueProperty="value" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template" class="flat">
			<div [ngClass]="size" class="col-12 row center-all justify-center wrap pad-lg">
				<div [ngClass]="[colour, size, type]" class="uf-chip">
					<div class="uf-avatar accent">
						A
					</div>
					User Avatar
				</div>
				<div [ngClass]="[colour, size, type]" class="uf-chip">
					<div class="uf-avatar success">
						<uf-icon name="tick" />
					</div>
					Status Avatar
				</div>
				<div [ngClass]="[colour, size, type]" class="uf-chip">
					Basic
				</div>
				<div [ngClass]="[colour, size, type]" class="uf-chip">
					Custom Action
					<button class="uf-action" type="button">
						<uf-icon name="edit" />
					</button>
				</div>
				<div [ngClass]="[colour, size, type]" class="uf-chip">
					Deletable
					<button class="uf-action" type="button">
						<uf-icon name="delete" />
					</button>
				</div>
				<div [ngClass]="[colour, size, type]" class="uf-chip focused">
					Focused
					<button class="uf-action" type="button">
						<uf-icon name="delete" />
					</button>
				</div>
				<div [ngClass]="[colour, size, type]" class="uf-chip">
					Multline chip<br>
					text
					<button class="uf-action" type="button">
						<uf-icon name="edit" />
					</button>
				</div>
			</div>
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>