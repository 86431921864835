import { Component, Injectable } from '@angular/core';
import { Client, ConnectionOptions, ConnectionOptionsInterface, ErrorType, Interceptor, MeClient, TenantClient, UsersClient, ensureUfError } from '@unifii/sdk';

import { ToastService } from '@unifii/library/common';

@Injectable()
export class ExampleInterceptor implements Interceptor {

	intercept(promise: Promise<any>): Promise<any> {

		try {
			// console.log('Intercepting success value');
			return promise;
		} catch (e) {
			const error = ensureUfError(e);

			// console.log('Intercepted error:', error);
			if (error.type === ErrorType.Unauthorized) {
				// react to Unauthorized
				return Promise.resolve(null);
			}

			// propagate the error down
			throw error;
		}
	}

}

const opts: ConnectionOptionsInterface = {
	baseUrl: 'https://dev.unifii.net',
	// cspell:disable-next-line
	apiKey: '9Y8E0E4P91UA3UMS8FQPBBPBA',
	appId: 'au.com.unifii.unifii.dev',
	platform: 'web',
	appVersion: 'x.y.z',
};

@Component({
	selector: 'sc-client',
	templateUrl: './show-client.html',
	providers: [
		{ provide: ConnectionOptions, useValue: opts },
		{ provide: Interceptor, useClass: ExampleInterceptor },
		{ provide: Client, useFactory: () => new Client(opts), deps: [Interceptor] },
		{ provide: TenantClient, useFactory: (client: Client) => new TenantClient(client), deps: [Client] },
		{ provide: UsersClient, useFactory: (client: Client) => new UsersClient(client), deps: [Client] },
	],
})
export class ShowClientComponent {

	protected error: any;
	protected success: any;

	protected oauthUsername: string;
	protected oauthPassword: string;

	protected username: string;
	protected userId: string;
	protected userToken: string;
	protected oldPassword: string;
	protected userPassword: string;

	constructor(
		private client: Client,
		private tenantClient: TenantClient,
		private usersClient: UsersClient,
		private meClient: MeClient,
		private toast: ToastService,
	) { }

	protected get options() {
		return (this.client as any).options as ConnectionOptionsInterface;
	}

	protected get isAuthenticated() {
		return this.client.token != null;
	}

	protected get isApiSecret() {
		return !this.isAuthenticated && (this.options.apiKey && this.options.apiSecret);
	}

	protected get isAppSecret() {
		return !this.isAuthenticated && !this.isApiSecret && this.options.appSecret && this.options.appSecret;
	}

	protected get hasApiSecret() {
		return this.options.apiSecret != null;
	}

	protected get hasAppSecret() {
		return this.options.appSecret != null;
	}

	protected toggleApiSecret() {

		if (this.hasApiSecret) {
			delete this.options.apiSecret;
		} else {
			// cspell:disable-next-line
			this.options.apiSecret = 'lSvzpIcIBnYFOWSLLRv5sZx6CWaAr8b_g4_xIrIIUCm4t6mxVmEF-sEzp0oWTbvovUMufW-79cFVi4qZlLvcLQ';
		}
	}

	protected toggleAppSecret() {
		if (this.hasAppSecret) {
			delete this.options.appSecret;
		} else {
			// cspell:disable-next-line
			this.options.appSecret = 'OgDsxBB5uDQGaHNkEjcYcY3Le1nBj5KqVX8hmDDynyfpItDSuB0NxM6PDW_ZZtMSuD509-oeROVxOec_wxCXoQ';
		}
	}

	protected async authenticate() {
		try {
			await this.client.authenticate(this.oauthUsername, this.oauthPassword);
			this.toast.success('Authenticated');
		} catch (e) {
			const error = ensureUfError(e);

			this.error = error.data ?? JSON.parse((error as any)._body);
		}
	}

	protected logout() {
		(this.client as any).token = null;
		this.toast.success('Logged out');
	}

	protected async loadProjects() {
		try {
			const projects = await this.tenantClient.getProjects();

			this.success = projects.map((p) => p.name).join(', ');
		} catch (e) {
			this.error = e;
		}
	}

	protected async requestResetPassword() {
		try {
			await this.usersClient.resetPassword(this.username);
			this.toast.success('Password reset');
		} catch (e) {
			this.error = e;
		}
	}

	protected async loadUser() {
		try {
			const userInfo = await this.meClient.get(this.userToken);

			this.success = JSON.stringify(userInfo);
		} catch (e) {
			this.error = e;
		}
	}

	protected async updatePassword() {
		try {
			await this.meClient.resetPassword({ oldPassword: this.userPassword, password: this.userPassword }, this.userToken);
			this.toast.success('Password updated');
		} catch (e) {
			this.error = e;
		}
	}

}
