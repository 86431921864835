<div class="uf-app-bar">
	<h3>{{ commonTK.LabelPrintForm | translate }}</h3>
</div>
<div class="uf-grid pad-left pad-right gaps scrollable-section">
	<p class="col-12">{{ commonTK.MessagePrintForm | translate }}</p>
	<uf-radio [options]="formStyleOptions" [(value)]="summary" class="col-12" valueProperty="identifier" />
</div>
<div class="uf-form-actions">
	<button (click)="close()" class="uf-button tertiary small left" type="button">
		{{ sharedTermsTK.ActionCancel | translate }}
	</button>
	<button (click)="confirm()" class="uf-button primary small" type="button" ufAutofocus>
		{{ sharedTermsTK.ActionConfirm | translate }}
	</button>
</div>