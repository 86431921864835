<sc-page-content identifier="progress">

	<ng-container editorInputs>
		<uf-radio [columns]="2" [options]="linearSizeOptions" [(value)]="linearSize" (valueChange)="modifierChange()"
			class="col-6 col-md-6" label="Size" valueProperty="value" />
		<uf-select [options]="durationOptions" [(value)]="duration" (valueChange)="durationChange($event)"
			class="col-12 col-md-6" label="Duration examples" valueProperty="value" />
	</ng-container>

	<ng-container editor>
		<h2>Linear</h2>
		<sc-code-editor-expander [code]="linearTemplate" class="flat">
			<div class="grow pad">
				<div style="position: relative;">
					<!-- inProgress debuggin -->
					<!-- <uf-progress [inProgress]="progressValue" [ngClass]="linearSize"></uf-progress> -->
					<uf-progress *ngIf="duration === 0; else timedProgress" [progress]="value" [ngClass]="linearSize" />
					<ng-template #timedProgress>
						<uf-progress [ngClass]="linearSize" [inProgress]="inProgress" />
					</ng-template>
				</div>
			</div>
		</sc-code-editor-expander>

		<h2>Circular</h2>
		<sc-code-editor-expander class="flat" code="<uf-spinner></uf-spinner>">
			<uf-spinner />
		</sc-code-editor-expander>

	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">Properites</h3>
		<sc-info-table [columnConfig]="propertiesColConfig" [rows]="propertiesRows" />
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>