import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentLinkFormData, FileData, LinkContentType, Option, PublishedContent, Query } from '@unifii/sdk';

import { CommonTranslationKey, FileUploader, Modal, ModalData, ModalRuntime, SharedTermsTranslationKey, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { FormSettings, SmartFormsTranslationKey } from '@unifii/library/smart-forms';

export interface ContentLinkPickerData {
	value?: ContentLinkFormData;
	label?: string;
	allowedTypes: LinkContentType[];
}

interface ContentLinkControlData {
	label: string;
	type: LinkContentType;
	id: string | FileData[] | Option;
	useViewer: boolean;
}

const createFileUploader = (formSettings: FormSettings) => formSettings.uploader;

enum ControlKeys {
	Type = 'type',
	Label = 'label',
	Id = 'id',
	UseViewer = 'useViewer'
}

@Component({
	templateUrl: './content-link-picker.html',
	providers: [{
		provide: FileUploader, useFactory: createFileUploader, deps: [FormSettings],
	}],
})
export class ContentLinkPickerComponent implements Modal<ContentLinkPickerData, ContentLinkFormData | undefined>, OnInit {

	@HostBinding('class.uf-form-card') cardClass = true;

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly smartFormsTK = SmartFormsTranslationKey;
	protected readonly commonTK = CommonTranslationKey;
	protected readonly controlKeys = ControlKeys;
	protected readonly linkContentType = LinkContentType;

	protected allowedTypes: { identifier: LinkContentType; name: string }[];
	protected type: LinkContentType | undefined;
	protected label: string;
	protected inProgress: boolean;
	protected form: UfControlGroup;
	protected contentOptions: Option[];

	private contentTypes: { identifier: LinkContentType; name: string }[] = [
		// { identifier: LinkContentType.Asset, name: this.translate.instant(CommonTranslationKey.ContentTypeAssetLabel) }, // TODO - Version 2
		{ identifier: LinkContentType.Attachment, name: this.translate.instant(CommonTranslationKey.ContentTypeAttachmentLabel) as string },
		{ identifier: LinkContentType.Form, name: this.translate.instant(CommonTranslationKey.ContentTypeFormLabel) as string },
		{ identifier: LinkContentType.Page, name: this.translate.instant(CommonTranslationKey.ContentTypePageLabel) as string },
		{ identifier: LinkContentType.Url, name: this.translate.instant(CommonTranslationKey.ContentTypeUrlLabel) as string },
	];

	constructor(
		public runtime: ModalRuntime<ContentLinkPickerData, ContentLinkFormData | undefined>,
		@Inject(ModalData) public data: ContentLinkPickerData,
		@Inject(PublishedContent) private content: PublishedContent,
		private ufFormBuilder: UfFormBuilder,
		private translate: TranslateService,
	) { }

	async ngOnInit() {
		const { label = '', value, allowedTypes } = this.data;

		this.label = label;
		this.allowedTypes = this.contentTypes.filter((t) => allowedTypes.includes(t.identifier));
		this.type = value?.type;

		const formData = await this.mapContentLinkToFormData(value);

		this.form = this.ufFormBuilder.group({
			type: [formData.type, ValidatorFunctions.required(this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired) as string)],
			id: [formData.id, ValidatorFunctions.required(this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired) as string)],
			label: [formData.label, ValidatorFunctions.required(this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired) as string)],
			useViewer: [formData.useViewer],
		});
	}

	close() {
		this.runtime.close();
	}

	// set label to selected item name
	protected select(value?: Option) {
		if (!value) {
			return;
		}

		const label = this.form.get(ControlKeys.Label);

		if (label?.untouched) {
			label.setValue(value.name);
		}
	}

	// set label to file name
	protected fileUploaded(value?: FileData[]) {
		if (!value?.length) {
			return;
		}

		const label = this.form.get(ControlKeys.Label);

		if (label?.untouched) {
			label.setValue(value[0]?.name);
		}
	}

	protected clear() {
		this.runtime.update(undefined);
		this.close();
	}

	protected save() {
		if (this.form.invalid) {
			this.form.setSubmitted();

			return;
		}

		this.runtime.update(this.mapFormDataToContentLink(this.form.value as ContentLinkControlData));
		this.close();
	}

	protected async loadContent(q: string) {
		const query = q ? new Query().q(q) : undefined;

		switch (this.type) {
			case LinkContentType.Form:
				this.contentOptions = (await this.content.queryForms(query)).map((d) => ({ identifier: d.identifier, name: d.label }));
				break;
			case LinkContentType.Page:
				this.contentOptions = (await this.content.queryPages(query)).map((p) => ({ identifier: p.identifier, name: p._title }));
				break;
			default:
				this.contentOptions = [];
		}
	}

	protected updateType() {
		this.type = this.form.get(this.controlKeys.Type)?.value as LinkContentType | undefined;
		this.form.get(this.controlKeys.Id)?.reset();
		this.form.get(this.controlKeys.Label)?.reset();
	}

	private async mapContentLinkToFormData(contentLink?: ContentLinkFormData): Promise<ContentLinkControlData> {

		const formData = {
			label: contentLink?.label,
			type: contentLink?.type,
			useViewer: contentLink?.useViewer,
		} as ContentLinkControlData;

		switch (contentLink?.type) {
			case LinkContentType.Attachment:
				formData.id = [{
					id: contentLink.id,
					name: contentLink.fileType ?? '',
				}];
				break;
			case LinkContentType.Page:
				formData.id = {
					identifier: contentLink.id,
					name: (await this.content.getPage(contentLink.id))._title,
				};
				break;
			case LinkContentType.Form:
				formData.id = {
					identifier: contentLink.id,
					name: (await this.content.getForm(contentLink.id)).label,
				};
				break;
			case LinkContentType.Url:
				formData.id = `${contentLink.id}`;
		}

		return formData;
	}

	private mapFormDataToContentLink(formData: ContentLinkControlData): ContentLinkFormData | undefined {

		const { label, type } = formData;
		let id = '';
		let fileType: string | undefined;
		let useViewer: boolean | undefined;

		switch (type) {
			case LinkContentType.Attachment: {
				const file = (formData.id as FileData[]).pop();

				fileType = file?.name;
				id = `${file?.id}`;
				useViewer = formData.useViewer ? formData.useViewer : undefined;
				break;
			}
			case LinkContentType.Page:
			case LinkContentType.Form:
				id = (formData.id as Option).identifier;
				break;
			case LinkContentType.Url:
				id = (formData.id as string);
		}

		return { id, label, type, fileType, useViewer };

	}

}
