<uf-form *ngIf="definition" [definition]="definition" [formData]="config.data" [disabled]="config.disabled === true"
	[config]="formConfig" (done)="imagesReady()" imagesLoaded>
	<div header class="uf-app-bar flat">
		<div class="title">
			{{definition.label}}
			<small [formData]="config.data" formDataLabel>
			</small>
		</div>
		<img *ngIf="logo" [attr.src]="logo + '?h=32'" class="logo" />
	</div>
	<ul *ngIf="urls.length" footer class="img-list">
		<li *ngFor="let url of urls">
			<img [attr.src]="url" class="appended-img" />
		</li>
	</ul>
</uf-form>

<uf-panel class="container loading-container">
	<div class="loading-content">
		<uf-spinner type="dots" />
		<p class="small">{{inputTK.FormPrintFeedbackLoading | translate}}</p>
	</div>
</uf-panel>