import { Definition, Dictionary, FieldTemplate, FieldType, FormContentTemplate, FormData, generateUUID } from '@unifii/sdk';

import { DateTimeFunctions, FormDefinitionMetadataIdentifiers, RuntimeDefinition, RuntimeField, RuntimeTransition, fieldIterator } from '@unifii/library/common';

import { WorkflowStartState, contentLevelMap } from '../constants';

import * as FieldHelperFunctions from './field-helper-functions';

/**
 * @description
 * get section with target state, and 'next' action or tag
 *
 * @param fields - Form fields
 * @param action - Current form data action
 * @param state - Current form data state
 */
export const targetSectionNextCondition = (fields: RuntimeField[], action?: string, state?: string): RuntimeField[] => {

	if (!action || !state) {
		return [];
	}

	const sections = fields.filter((f) => f.type === FieldType.Section && f.transitions.some((t) => t.target === state && t.action === action));

	// legacy case - can look at removing later
	if (action === 'Next') {
		return sections;
	}

	return sections.filter((s) => s.transitions.some((t) =>
		t.action === action &&
		(t.tags.some((v) => v.toLowerCase() === 'next') || t.keepOpen),
	));
};

/**
 * @description
 * gets sections from form with next condition,
 * and returns returns true if at least one next section is accessible by user
 *
 * @param fields - Form fields
 * @param formData - Current form data
 * @param userRoles - Current user roles
 */
export const canKeepEditingOnNext = (fields: RuntimeField[], formData: FormData, userRoles?: string[]): boolean => {

	const sections = targetSectionNextCondition(fields, formData._action, formData._state);

	return sections.some((s) => FieldHelperFunctions.areRolesMatching(userRoles, s.roles));
};

/**
 * @description
 * scroll to first active section
 *
 * @param nativeElement - form native element
 */
export const scrollToActiveSection = (nativeElement: HTMLElement) => {
	const activeSection = nativeElement.querySelector('uf-section[data-active="true"]');

	activeSection?.scrollIntoView(true);
};

/** Current timestamp as same format of back-end */
export const getUTCTime = (): string =>
	DateTimeFunctions.getUTCTimestamp();

export const getTransitions = (definition?: RuntimeDefinition | null): RuntimeTransition[] => {

	if (!definition) {
		return [];
	}

	return definition.fields
		.filter((field) => field.type === FieldType.Section)
		.map((field) => field.transitions)
		.reduce((prev, curr) => prev.concat(curr), []);
};

export const getStates = (definition?: RuntimeDefinition | null): string[] =>
	[...new Set([WorkflowStartState, ...getTransitions(definition)
		.map((t) => [t.source, t.target])
		.reduce((prev, curr) => prev.concat(curr), [])])];

/**
 *
 * @param formData existing FormData
 * @param definition the associated Form definition
 * @returns amended existing FormData or a new one otherwise
 */
export const amendFormData = (formData: FormData = {}, definition?: RuntimeDefinition | Definition): FormData => {

	formData.id = formData.id ?? generateUUID();
	formData._state = formData._state ?? WorkflowStartState;
	formData._history = formData._history ?? [];
	formData._definitionIdentifier = formData._definitionIdentifier ?? definition?.identifier;
	formData._definitionVersion = formData._definitionVersion ?? definition?.version;

	return formData;
};

export const amendDefinitionAutofills = (definition: RuntimeDefinition, autofillExpressionsEntries: Dictionary<any>) => {

	const autofillsKeys = Object.keys(autofillExpressionsEntries);
	const autofillsKeysCount = Object.keys(autofillsKeys).length;
	let appliedKeys = 0;

	for (const { field } of fieldIterator(definition, undefined, {
		canDive: (f) => [FieldType.Section, FieldType.Group, FieldType.Stepper, FieldType.Step].includes(f.type),
		canIterate: (f) => autofillAllowedTypes.includes(f.type),
	})) {
		// No more autofills available, stop the iteration
		if (appliedKeys >= autofillsKeysCount) {
			break;
		}

		// Guard for fields without identifier
		if (!field.identifier) {
			continue;
		}

		// Guard for fields without autofillEntry
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const autofillRawValue = autofillExpressionsEntries[field.identifier];

		if (autofillRawValue == null) {
			continue;
		}

		let autofillValue;

		if (field.type === FieldType.Number) {
			if (typeof autofillRawValue === 'number') {
				autofillValue = `${autofillRawValue}`;
			} else {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				autofillValue = autofillRawValue;
			}
		} else {
			autofillValue = `'${autofillRawValue}'`;
		}

		if (autofillValue) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			field.autofill = autofillValue;
			appliedKeys++;
		}
	}
};

export const removeMetadataFields = (formData: FormData) => {
	const updatedFormData = Object.assign({}, formData);

	const smartFormsKeys = Object.values(FormDefinitionMetadataIdentifiers);

	for (const key of smartFormsKeys) {
		// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
		delete updatedFormData[key];
	}

	return updatedFormData;
};

export const getContentClasses = (templateConfig?: FormContentTemplate, isActive?: boolean, template?: FieldTemplate) => {
	const classes: string[] = [];

	if (template) {
		classes.push(contentLevelMap[template] ?? template);
		classes.push('raised');
	}

	if (!template || !isActive || templateConfig?.activeBackgroundUntinted) {
		classes.push('untinted-background');
	}

	if (!isActive) {
		classes.push('inactive');
	}

	return classes;
};

export const isFieldEligibleForNavigation = (field: RuntimeField): boolean => {
	return [FieldType.Section, FieldType.Step, FieldType.Stepper].includes(field.type) ||
			(field.type === FieldType.Group && field.parent?.type === FieldType.Section);
};

const autofillAllowedTypes = [
	FieldType.Text,
	FieldType.MultiText,
	FieldType.Number,
	FieldType.Date,
	FieldType.Time,
	FieldType.DateTime,
	FieldType.Phone,
	FieldType.Email,
	FieldType.Website,
	FieldType.Lookup,
	FieldType.Hierarchy,
	// TODO restore once choices are migrated to DataSeed format
	// FieldType.Choice,
	// FieldType.MultiChoice,
];
