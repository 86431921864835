import { Component, HostBinding, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CommonTranslationKey, Modal, ModalRuntime, SharedTermsTranslationKey } from '@unifii/library/common';

@Component({
	selector: 'uf-print-form-select-style-modal',
	templateUrl: 'print-form-select-style-modal.html',
})
export class PrintFormSelectStyleModalComponent implements Modal<void, boolean> {

	@HostBinding('class.uf-form-card') cardClass = true;

	runtime = inject<ModalRuntime<void, boolean>>(ModalRuntime);

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly commonTK = CommonTranslationKey;
	protected readonly formStyleOptions = [
		{ identifier: false, name: inject(TranslateService).instant(CommonTranslationKey.LabelFullForm) as string },
		{ identifier: true, name: inject(TranslateService).instant(CommonTranslationKey.LabelSummary) as string },
	];

	protected summary = false;

	close() {
		this.runtime.close();
	}

	protected confirm() {
		this.runtime.close(this.summary);
	}

}
