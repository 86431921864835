import { Injectable, Type } from '@angular/core';

import { DataDisplayAttachmentComponent, DataDisplayContentModalComponent, DataDisplayDataSeedComponent, DataDisplayHrefComponent, DataDisplayIconComponent, DataDisplayImageComponent, DataDisplayLozengeComponent, DataDisplayMapMarkerComponent, DataDisplayMarkdownComponent, DataDisplayOptionsComponent, DataDisplayPrimitiveComponent, DataDisplayRouterLinkComponent, DataDisplaySignatureComponent } from '../../components';
import { DataDisplayComponentType } from '../../models';

import { DataDisplayComponentRegistry } from './data-display-component-registry';

@Injectable()
export class UfDataDisplayComponentRegistry implements DataDisplayComponentRegistry {

	/** components order determine the priority of value against the isDataAccepted matchers */
	protected components: { isDataAccepted: (value: unknown) => boolean }[] = [
		DataDisplayPrimitiveComponent,
		DataDisplayImageComponent,
		DataDisplayLozengeComponent,
		DataDisplayIconComponent,
		DataDisplayHrefComponent,
		DataDisplayRouterLinkComponent,
		DataDisplayMapMarkerComponent,
		DataDisplayMarkdownComponent,
		DataDisplayContentModalComponent,
		DataDisplaySignatureComponent,
		DataDisplayAttachmentComponent,
		DataDisplayOptionsComponent,
		DataDisplayDataSeedComponent,
	];

	preDataTransform(data: unknown): unknown {
		return data;
	}

	get(value: unknown): Type<DataDisplayComponentType> | null {
		const match = (this.components.find((component) => component.isDataAccepted(value)) ?? null) as Type<DataDisplayComponentType> | null;

		if (!match && value != null) {
			console.warn(`UfDataDisplayComponentRegistry.get - no match`, value);
		}

		return match;
	}

}
