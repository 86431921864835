import { Inject, Injectable } from '@angular/core';
import { CostModelFormat, DataSeed, DataSourceType, Dictionary, HierarchyUnit, Option, ZonedDateTime } from '@unifii/sdk';

import { DataSourceLoader, FilterEntry, FilterSerializer, FilterType, FilterValue, HierarchyUnitProvider, NumberRange, TempoRange, ZonedTempoRange } from '../../models';

@Injectable()
export class UfFilterSerializer<V extends FilterValue, E extends FilterEntry> implements FilterSerializer<V, E> {

	private seedCache = new Map<string, Promise<DataSeed | null>>();
	private unitCache = new Map<string, Promise<HierarchyUnit | null | undefined>>();

	constructor(@Inject(HierarchyUnitProvider) private hierarchyUnitProvider: HierarchyUnitProvider) { }

	serialize(value: V, { type }: E): string | null {
		if (value == null || (typeof value === 'string' && !value)) {
			return null;
		}

		switch (type) {
			case FilterType.NumberRange:
			case FilterType.TimeRange:
			case FilterType.DateRange:
			case FilterType.DatetimeRange:
			case FilterType.ZonedDatetimeRange:
				return this.serializeRange(value as TempoRange | ZonedTempoRange | NumberRange);
			case FilterType.Cost:
				return '' + (value as CostModelFormat).amount;
			case FilterType.HierarchyUnit:
				return this.serializeUnit(value as HierarchyUnit);
			case FilterType.DataSeed:
				return this.serializeDataSeed(value as DataSeed);
			case FilterType.OptionArray:
				return this.serializeOptionArray(value as Option[]);
			case FilterType.TextArray:
				return this.serializeTextArray(value as string[]);
			case FilterType.User:
			case FilterType.Company:
			case FilterType.DataSeedArray:
				return this.serializeDataSeedArray(value as DataSeed[]);
			case FilterType.ZonedDatetime:
				return this.serializeZonedDatetime(value as ZonedDateTime);
			default:
				return `${value as string | number}`;
		}
	}

	async deserialize(value: string | null, { type, options, loader }: E): Promise<V> {
		if (value == null || !value) {
			return null as V;
		}

		value = decodeURIComponent(value);

		switch (type) {
			case FilterType.TimeRange:
			case FilterType.DateRange:
			case FilterType.DatetimeRange:
				return this.deserializeRange(value) as V;
			case FilterType.ZonedDatetimeRange:
				return this.deserializesZonedDateTimeRange(value) as V;
			case FilterType.NumberRange:
				return this.deserializeRange(value, true) as V;
			case FilterType.Number:
				return +value as V;
			case FilterType.Cost:
				return { amount: +value, currency: 'AU' } as V;
			case FilterType.ZonedDatetime:
				return this.deserializeZonedDatetime(value) as V;
			case FilterType.DataSeed:
				return await this.deserializeDataSeed(value, loader as DataSourceLoader) as V;
			case FilterType.TextArray:
				return this.deserializeTextArray(value) as V;
			case FilterType.OptionArray:
				return this.deserializeOptions(value, options ?? []) as V;
			case FilterType.User:
			case FilterType.Company:
			case FilterType.DataSeedArray:
				return await this.deserializeDataSeeds(value, loader as DataSourceLoader) as V;
			case FilterType.HierarchyUnit:
				return await this.deserializeHierarchyUnit(value) as V;
			default:
				return value as V;
		}
	}

	serializeAll(values: Dictionary<V>, entries: E[]): Dictionary<string | null> {
		const serializedValues: Dictionary<string> = {};

		for (const entry of entries) {
			const { identifier } = entry;
			const deserializedValue = values[identifier];
			const value = deserializedValue ? this.serialize(deserializedValue, entry) : undefined;

			if (value != null) {
				serializedValues[identifier] = value;
			}
		}

		return serializedValues;
	}

	async deserializeAll(values: Dictionary<string | null>, entries: E[]): Promise<Dictionary<V>> {
		const filterValues: Dictionary<V> = {};

		for (const entry of entries) {
			const { identifier } = entry;
			const serializedValue = values[identifier];

			const value = serializedValue ? await this.deserialize(serializedValue, entry) : undefined;

			if (value != null) {
				filterValues[identifier] = value;
			}
		}

		return filterValues;
	}

	private serializeZonedDatetime(value: ZonedDateTime): string {
		return value.value + '|' + value.tz;
	}

	private serializeDataSeed(value: DataSeed): string {
		this.seedCache.set(value._id, Promise.resolve(value));

		return '' + value._id;
	}

	private serializeUnit(unit: HierarchyUnit): string {
		this.unitCache.set(unit.id, Promise.resolve(unit));

		return unit.id;
	}

	private serializeOptionArray(data: Option[]): string | null {
		if (!data.length) {
			return null;
		}

		return data.map((o) => o.identifier).join(',');
	}

	private serializeTextArray(data: string[]): string | null {
		if (!data.length) {
			return null;
		}

		return data.join(',');
	}

	private serializeDataSeedArray(data: DataSeed[]): string | null {
		if (!data.length) {
			return null;
		}

		return data.map((v) => {
			const id = v._id;

			this.seedCache.set(id, Promise.resolve(v));

			return id;
		}).join(',');
	}

	private serializeRange(range: NumberRange | TempoRange | ZonedTempoRange): string {
		let from = range.from ?? '';
		let to = range.to ?? '';

		if (typeof from === 'object') {
			from = this.serializeZonedDatetime(from);
		}

		if (typeof to === 'object') {
			to = this.serializeZonedDatetime(to);
		}

		return `${from},${to}`;
	}

	private deserializeTextArray(value: string): string[] {
		if (!value.trim().length) {
			return [];
		}

		return value.trim().split(',');
	}

	private deserializeOptions(value: string, options: Option[]): Option[] {
		const identifiers = value.trim().split(',');

		return identifiers.map((i) => options.find((o) => o.identifier === i) ?? { identifier: i, name: i });
	}

	private deserializeRange(value: string, isNumber = false): NumberRange | TempoRange {
		const [from, to] = value.trim().split(',').map((p) => p.trim());

		const range: NumberRange | TempoRange = {};

		if (from) {
			range.from = isNumber ? +from : from;
		}
		if (to) {
			range.to = isNumber ? +to : to;
		}

		return range;
	}

	private deserializeZonedDatetime(str: string | null | undefined): ZonedDateTime | undefined {
		if (!str?.trim() || !str.includes('|')) {
			return;
		}

		const [value, tz] = str.split('|').map((v) => v.trim());

		return value && tz ? { value, tz } : undefined;
	}

	private deserializesZonedDateTimeRange(value: string): ZonedTempoRange {
		const [from, to] = value.trim().split(',').map((p) => p.trim());

		return {
			from: this.deserializeZonedDatetime(from),
			to: this.deserializeZonedDatetime(to),
		};
	}

	private async deserializeHierarchyUnit(id: string): Promise<HierarchyUnit | null> {

		let cachedPromise = this.unitCache.get(id);

		if (cachedPromise == null) {
			cachedPromise = this.hierarchyUnitProvider.getUnit(id);
			this.unitCache.set(id, cachedPromise);
		}

		try {
			const unit = await cachedPromise;

			if (unit != null) {
				return unit;
			}
		} catch (e) {
			// clear cache on errors
			this.seedCache.delete(id);
		}

		return null;
	}

	private async deserializeDataSeeds(value: string, loader?: DataSourceLoader): Promise<DataSeed[] | null> {
		if (loader == null) {
			console.error('Loader not provided for DataSeed deserialization');

			return null;
		}

		const ids = value.trim().split(',');
		const seeds: DataSeed[] = [];

		for (const id of ids) {
			const seed = await this.deserializeDataSeed(id, loader);

			if (seed != null) {
				seeds.push(seed);
			}
		}

		return seeds;
	}

	private async deserializeDataSeed(id: string, loader?: DataSourceLoader): Promise<DataSeed | null> {
		if (loader == null) {
			console.error('Loader not provided for DataSeed deserialization');

			return null;
		}

		try {
			let cachedPromise = this.seedCache.get(id);

			if (cachedPromise == null) {
				cachedPromise = loader.get(id);
				this.seedCache.set(id, cachedPromise);
			}

			const seed = await cachedPromise;

			if (seed != null) {
				return this.parseSeed(seed, loader.sourceConfig.type);
			}
		} catch (e) {
			// clear cache on errors
			this.seedCache.delete(id);
		}

		return this.parseSeed({ _id: id, _display: id }, loader.sourceConfig.type);
	}

	// TODO looks very specific to collection data sources may have to be moved
	private parseSeed(seed: DataSeed, type?: DataSourceType): DataSeed {
		if (type === DataSourceType.Collection) {
			seed._id = +seed._id as any as string;
		}

		return seed;
	}

}
