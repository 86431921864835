export enum SharedTermsTranslationKey {
	ActionCollapse = 'action_collapse',
	ActionCollapseAll = 'action_collapse_all',
	ActionExpand = 'action_expand',
	ActionExpandAll = 'action_expand_all',
	ActionAdd = 'action_add',
	ActionCreate = 'action_create',
	ActionCreateSuccess = 'action_create_success',
	ActionRemove = 'action_remove',
	ActionDelete = 'action_delete',
	ActionDeleteFeedbackSuccess = 'action_delete_feedback_success',
	ActionClear = 'action_clear',
	ActionClearAll = 'action_clear_all',
	ActionCopy = 'action_copy',
	ActionCopyFeedbackSuccess = 'action_copy_feedback_success',
	ActionCopyFeedbackFail = 'action_copy_feedback_fail',
	ActionClose = 'action_close',
	ActionLock = 'action_lock',
	ActionUnlock = 'action_unlock',
	ActionView = 'action_view',
	ActionEdit = 'action_edit',
	ActionShow = 'action_show',
	ActionShowMore = 'action_show_more',
	ActionHide = 'action_hide',
	ActionSelect = 'action_select',
	ActionSort = 'action_sort',
	ActionSortBy = 'action_sort_by',
	ActionConfirm = 'action_confirm',
	ActionCancel = 'action_cancel',
	ActionOk = 'action_ok',
	ActionSubmit = 'action_submit',
	ActionComplete = 'action_complete',
	ActionRetry = 'action_retry',
	ActionRefresh = 'action_refresh',
	ActionFilter = 'action_filter',
	ActionLoad = 'action_load',
	ActionDownload = 'action_download',
	ActionUpload = 'action_upload',
	ActionGeolocate = 'action_geolocate',
	ActionSave = 'action_save',
	ActionSaveFeedbackSuccess = 'action_save_feedback_success',
	ActionSaveAndNext = 'action_save_and_next',
	ActionSaveAndClose = 'action_save_and_close',
	ActionInvite = 'action_invite',
	ActionInviteSuccess = 'action_invite_feedback_success',
	ActionLogin = 'action_login',
	ActionLogout = 'action_logout',
	ActionNext = 'action_next',
	ActionBack = 'action_back',
	ActionGo = 'action_go',
	ActionChange = 'action_change',
	ActionSignInWithMicrosoft = 'action_sign_in_with_microsoft',
	ActionSignInWithAuth0 = 'action_sign_in_with_auth0',
	ActionSignInWithOkta = 'action_sign_in_with_okta',
	ActionSignInWithUnifii = 'action_sign_in_with_unifii',
	ActionReturnToApp = 'action_return_to_app',
	Error = 'error',
	ErrorUnknown = 'error_unknown',
	ErrorServer = 'error_server',
	ErrorForbidden = 'error_forbidden',
	ErrorGenericMessage = 'error_generic_message',
	ValidatorValueRequired = 'validator_value_required',
	ValidatorInvalidCharacters = 'validator_invalid_characters',
	ValidatorInvalidSpaces = 'validator_contains_spaces',
	ValidatorInvalidEmail = 'validator_invalid_email',
	ValidatorInvalidWebsite = 'validator_invalid_website',
	BrowserNotSupported = 'browser_not_supported',
	BrowserNotSupportAudio = 'browser_not_support_audio',
	BrowserNotSupportVideo = 'browser_not_support_video',
	SearchLabelNoResults = 'search_label_no_results',
	SearchLabelSelectResults = 'search_label_select_result',
	SearchLabelMinSearchLength = 'search_label_min_search_length',
	SearchActionClear = 'search_action_clear',
	NewLabel = 'new_label',
	DateJanuaryLabel = 'date_january_label',
	DateFebruaryLabel = 'date_february_label',
	DateMarchLabel = 'date_march_label',
	DateAprilLabel = 'date_april_label',
	DateMayLabel = 'date_may_label',
	DateJuneLabel = 'date_june_label',
	DateJulyLabel = 'date_july_label',
	DateAugustLabel = 'date_august_label',
	DateSeptemberLabel = 'date_september_label',
	DateOctoberLabel = 'date_october_label',
	DateNovemberLabel = 'date_november_label',
	DateDecemberLabel = 'date_december_label',
	DateSundayLabel = 'date_sunday_label',
	DateMondayLabel = 'date_monday_label',
	DateTuesdayLabel = 'date_tuesday_label',
	DateWednesdayLabel = 'date_wednesday_label',
	DateThursdayLabel = 'date_thursday_label',
	DateFridayLabel = 'date_friday_label',
	DateSaturdayLabel = 'date_saturday_label',
	DateSundayShortLabel = 'date_sunday_short_label',
	DateMondayShortLabel = 'date_monday_short_label',
	DateTuesdayShortLabel = 'date_tuesday_short_label',
	DateWednesdayShortLabel = 'date_wednesday_short_label',
	DateThursdayShortLabel = 'date_thursday_short_label',
	DateFridayShortLabel = 'date_friday_short_label',
	DateSaturdayShortLabel = 'date_saturday_short_label',
	TimeHourLabel = 'time_hour_label',
	TimeMinuteLabel = 'time_minute_label',
	UpdateLabel = 'update_label',
	NowLabel = 'now_label',
	ResultsLabel = 'results_label',
    PageViewEmptyTablesMessage = 'page_view_empty_tables_message',
}
