<sc-page-content identifier="data-display-components">

	<ng-container editor>
		<div *ngFor="let entry of entries">
			<label class="large">{{entry.title}}</label>
			<uf-help [content]="entry.help" />
			<sc-code-editor-expander [code]="entry.json ?? ''" class="gap-bottom">
				<uf-data-display [data]="entry.description" />
			</sc-code-editor-expander>
		</div>
	</ng-container>

</sc-page-content>