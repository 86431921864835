<sc-page-content identifier="carousel">

	<ng-container editorInputs>
		<uf-radio [options]="intervalOptions" [(value)]="interval" (valueChange)="modifierChange()" label="Interval"
			valueProperty="value" columns="1" class="col-6" />
		<div class="col-6 col">
			<uf-checkbox [(value)]="required" (valueChange)="modifierChange()" label="Required" />
			<uf-checkbox *ngIf="required" [(value)]="showError" (valueChange)="modifierChange()" label="Show Error" />
		</div>

	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template">
			<uf-carousel [ngClass]="class" [interval]="interval" [required]="required" [showError]="showError">
				<uf-carousel-page class="flex" color="success">
					<uf-message [content]="content + '  ' + content" icon="successSolid" class="success raised grow" />
				</uf-carousel-page>
				<uf-carousel-page class="flex" color="warning">
					<uf-message [content]="content + '  ' + content" icon="successSolid" class="warning raised grow" />
				</uf-carousel-page>
				<uf-carousel-page class="flex" color="info">
					<uf-message [content]="content + '  ' + content" icon="successSolid" class="info raised grow" />
				</uf-carousel-page>
				<uf-carousel-page class="flex" color="error">
					<uf-message [content]="content + '  ' + content" icon="successSolid" class="error raised grow" />
				</uf-carousel-page>
				<uf-carousel-page class="flex">
					<uf-message [content]="content" class="error raised grow" />
				</uf-carousel-page>
				<uf-carousel-page class="padded">
					<div>
						<h1>Page 3</h1>
					</div>
				</uf-carousel-page>
			</uf-carousel>
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="tableConfig" [rows]="tableInfo" />
	</ng-container>

</sc-page-content>