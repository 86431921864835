<sc-page-content identifier="tables">

	<ng-container editorInputs>
		<uf-radio [columns]="1" [options]="colourOptions" [(value)]="colour" (valueChange)="modifierChange()"
			class="col-4" label="Colour" valueProperty="value" />
		<uf-radio [columns]="1" [options]="contentOptions" [(value)]="content"
			(valueChange)="contentChange();modifierChange()" class="col-4" label="Content" valueProperty="value" />
		<uf-radio [columns]="1" [options]="sizeOptions" [(value)]="size"
			(valueChange)="contentChange();modifierChange();" class="col-4" label="size" valueProperty="value" />
		<uf-checkbox [(value)]="outline" (valueChange)="contentChange();modifierChange();" class="col-4"
			label="Outline" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template" class="flat">
			<sc-info-table
				[cssClasses]="[size, colour, content === 'words' ? 'break-word' : '', outline ? 'outline': '']"
				[columnConfig]="demoConfig" [rows]="demoTable" class="grow pad-sides" />
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>