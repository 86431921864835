import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { Animations } from '../../constants';

@Component({
	selector: 'uf-drawer',
	templateUrl: './drawer.html',
	styleUrls: ['./drawer.less'],
	animations: [Animations.fadeInOut],
})
export class DrawerComponent {

	@Input() open = false;
	@Input() showHandle = false;
	@Output() openChange = new EventEmitter<boolean>();

	@HostBinding('class.visible') get visibility() {
		return this.open;
	}

	close() {
		this.updateOpen(false);
	}

	protected updateOpen(isOpen: boolean) {
		this.open = isOpen;
		this.openChange.emit(isOpen);
	}

}
