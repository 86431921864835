<sc-page-content identifier="breadcrumbs">

	<ng-container editorInputs>
		<uf-radio [options]="sizeOptions" [(value)]="size" (valueChange)="modifierChange()" class="col-6"
			label="Duration" columns="1" valueProperty="value" />
		<uf-radio [options]="minimizeOptions" [(value)]="minimize" (valueChange)="modifierChange()" class="col-6"
			label="minimize on mobile" columns="1" valueProperty="value" />
		<uf-radio [options]="colourOptions" [(value)]="colour" (valueChange)="modifierChange()" class="col-6"
			label="Colour" columns="1" valueProperty="value" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template">
			<uf-breadcrumbs [breadcrumbs]="demoBreadcrumbs" [ngClass]="class" [minimize]="!!minimize" />
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">Properites</h3>
		<sc-info-table [columnConfig]="propertiesColConfig" [rows]="propertiesRows" />
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>