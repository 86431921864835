<sc-page-content identifier="form-cards">

	<ng-container editorInputs>
		<uf-radio [options]="contentOptions" [(value)]="content" (valueChange)="modifierChange()" class="col-6"
			label="Content Variations" columns="2" valueProperty="identifier" />
		<div class="col-6">
			<button (click)="startProgress()" class="uf-button small" type="button">Show progress</button>
		</div>
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template">
			<div class="uf-form-card" style="max-width: 420px; min-width: 420px; min-height: 340px;">
				<uf-progress class="large" />
				<ng-container [ngSwitch]="content">
					<ng-container *ngSwitchCase="contentValues.Scrollable">
						<uf-panel class="stretch col">
							<ng-container *ngTemplateOutlet="appBarBlock" />
							<div class="grow">
								<ng-container *ngTemplateOutlet="contentBlock" />
							</div>
							<ng-container *ngTemplateOutlet="formActionsBlock" />
						</uf-panel>
					</ng-container>
					<ng-container *ngSwitchCase="contentValues.ScrollableContent">
						<ng-container *ngTemplateOutlet="appBarBlock" />
						<uf-panel class="grow">
							<ng-container *ngTemplateOutlet="contentBlock" />
						</uf-panel>
						<ng-container *ngTemplateOutlet="formActionsBlock" />
					</ng-container>
					<ng-container *ngSwitchDefault>
						<ng-container *ngTemplateOutlet="appBarBlock" />
						<ng-container *ngTemplateOutlet="contentBlock" />
						<ng-container *ngTemplateOutlet="formActionsBlock" />
					</ng-container>
				</ng-container>
			</div>
		</sc-code-editor-expander>
	</ng-container>

</sc-page-content>

<ng-template #progressBlock>
	<uf-progress class="large" />
</ng-template>

<ng-template #appBarBlock>
	<div class="uf-app-bar">
		<a class="uf-action tertiary">
			<uf-icon name="contextual" />
		</a>
		<h3>Update Details</h3>
	</div>
</ng-template>

<ng-template #contentBlock>
	<div class="uf-grid pad gaps">
		<uf-text class="col-12" label="Given name" />
		<uf-text class="col-12" label="Surname" />
		<uf-email class="col-12" label="Email" />
		<uf-phone class="col-12" label="Phone" />
	</div>
</ng-template>

<ng-template #formActionsBlock>
	<div class="uf-form-actions">
		<button type="button" class="uf-button tertiary left">
			Cancel
		</button>
		<button (click)="startProgress()" type="button" class="uf-button primary">
			Submit
		</button>
	</div>
</ng-template>