<uf-expander *ngIf="field" [actionBtn]="true">
	<div [class.small]="isSummary && control.disabled" expanderHeader class="uf-app-bar medium">
		<uf-icon *ngIf="field.icon" [name]="field.icon" />
		<div class="title">
			{{field.label}}
			<uf-help *ngIf="field.help" [content]="field.help" icon="infoSolid" class="grow" />
			<span class="small right">{{ commonTK.LabelTotal | translate}} {{ items.length }}</span>
		</div>
	</div>

	<div expanderBody class="grid">

		<ng-container *ngIf="items.length">

			<ng-container *ngIf="!field.template || displayForms">
				<ng-container *ngFor="let scope of items; let i = index">
					<uf-repeat-form [index]="i" [field]="field" [scope]="scope" (remove)="remove(i)"
						(contentChange)="childContentChange()" class="col-1of1 uf-form-group" />
				</ng-container>
			</ng-container>

			<ng-container
				*ngIf="(field.template === fieldTemplates.HorizontalTable && !displayForms) || field.template === fieldTemplates.HorizontalTableMobile">
				<uf-repeat-table [class.summary]="isSummary" [class.disabled]="control.disabled" [field]="field"
					[items]="items" (remove)="remove($event)" (rowClick)="edit($event)"
					class="uf-form-group col-1of1" />
			</ng-container>

			<ng-container
				*ngIf="(field.template === fieldTemplates.VerticalTable && !displayForms) || field.template === fieldTemplates.VerticalTableMobile">
				<uf-repeat-columns [class.disabled]="control.disabled && !isSummary" [field]="field" [items]="items"
					(remove)="remove($event)" (columnClick)="edit($event)" class="uf-form-group col-1of1" />
			</ng-container>

		</ng-container>

		<!-- normal Add (no DS) -->
		<div *ngIf="canAddItem" class="footer col-1of1">
			<button *ngIf="!formFieldService.sourceConfig" [disabled]="maxReached" (click)="add()"
				class="uf-button small" type="button">{{field.addButtonLabel || (sharedTermsTK.ActionAdd |
				translate)}}</button>
			<uf-error [control]="control" />
		</div>

		<!-- autocomplete Add (DS) -->
		<ng-container *ngIf="!disabled && !control.disabled && formFieldService.sourceConfig">
			<uf-search-result [placeholder]="field.addButtonLabel || (commonTK.SearchLabel | translate)"
				[disabled]="control.disabled || maxReached" [minSearchLength]="formSettings.dataSourceMinSearchLength"
				[options]="options" [blurOnSelect]="true" (searchChange)="loadOptions($event)"
				(valueChange)="add($event)" nameProperty="_display" class="col-1of1">
				<ng-container actions *ngIf="field.dataCapture?.barcode">
					<div class="input-action-btn">
						<uf-barcode-scanner [disabled]="control.disabled" (valueChange)="onScan($event)" />
					</div>
				</ng-container>
			</uf-search-result>
		</ng-container>

	</div>
</uf-expander>