import { Component, OnInit, inject } from '@angular/core';
import { HierarchyUnitsPath } from '@unifii/sdk';

import { DataLookupService, UfControl } from '@unifii/library/common';

@Component({
	selector: 'sc-empty',
	templateUrl: './show-empty.html',
	styleUrls: ['./show-empty.less'],
})
export class ShowEmptyComponent implements OnInit {

	unitsPaths: HierarchyUnitsPath = [];

	control = new UfControl(undefined, undefined, undefined, this.unitsPaths);

	dataLookupService = inject(DataLookupService);
	
	ngOnInit(): void {
		this.dataLookupService.lookupData({}, 'a.b');
	}

}
