import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { FieldType } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { FnsDatetimeUtc, RuntimeField, Scope, StepComponent, StepperComponent, UfControlGroup, parseFallbackISO } from '@unifii/library/common';
import { FormService, NavigationService, ScopeManager, WorkflowService, getUTCTime } from '@unifii/library/smart-forms';

import { SectionComponent } from './section.component';

interface StepData {
	_lastViewedAt: string;
	_lastCompletedAt?: string;
}

@Component({
	selector: 'uf-form-stepper',
	templateUrl: './form-stepper.html',
	styleUrls: ['./form-stepper.less'],
})
export class FormStepperComponent implements OnInit, AfterViewInit, OnDestroy {

	@Input({ required: true }) field: RuntimeField;
	@Input({ required: true }) scope: Scope;
	@Input({ required: true }) control: UfControlGroup;
	@ViewChild(StepperComponent) stepper: StepperComponent<RuntimeField>;

	protected scrollAnchor?: HTMLElement = inject(SectionComponent).elementRef.nativeElement;
	protected visibleSteps: RuntimeField[] = [];
	protected startingStep?: RuntimeField;

	private activeStep?: RuntimeField;
	private formService = inject(FormService);
	private navService = inject<NavigationService<RuntimeField>>(NavigationService);
	private workflowService = inject(WorkflowService);
	private scopeManager = inject(ScopeManager);
	private subscription = new Subscription();

	ngOnInit() {
		const registered = new Set<RuntimeField>();
		const viewedSteps: { field: RuntimeField; data: StepData }[] = [];

		for (const { field, data } of this.iterateStepInfo(this.field.fields)) {
			this.visibleSteps.push(field);

			if (field.parent && !registered.has(field.parent)) {
				this.navService.register(field.parent);

				registered.add(field);
			}

			if (data) {
				viewedSteps.push({ field, data });
				continue;
			}

			if (this.visibleSteps.length !== 1 && this.field.isRequired) {
				this.navService.setDisabled(field, true);
			}
		}

		this.startingStep = this.getStartingStep(viewedSteps);
	}

	ngAfterViewInit() {
		if (this.isNavigationEnabled) {
			/**
			 * Navigation initialization must occur after the view is ready. 
			 * The setup of navigation relies on control values which have their value set by template bindings.
			 */
			for (const field of this.visibleSteps) {
				if (this.getStepData(field)?._lastCompletedAt && this.isStepValid(field)) {
					this.navService.setSuccess(field, true);
				}
			}
	
			this.subscription.add(this.navService.onNavigation.subscribe((key) => {
				if (this.visibleSteps.includes(key)) {
					this.stepper.navigateToByKey(key);
					this.setLastViewedAt(key);
				}
			}));
	
			this.subscription.add(this.workflowService.transitionRequested.subscribe(() => {
				// a workflow transition needs to be treated the same way as a completed event on a step
				if (this.activeStep && this.isStepValid(this.activeStep)) {
					this.setSuccessAndCompleted(this.activeStep);
				}
			}));
		}
	}

	ngOnDestroy() {
		this.workflowService.workflowMode = 'default';
		this.subscription.unsubscribe();
	}

	protected isVisible(child: RuntimeField): boolean {
		return this.workflowService.isVisible(child);
	}

	protected stepActivated({ key }: StepComponent<RuntimeField>) {
		if (!key) {
			throw new Error('FormStepperComponent: step component must have key defined');
		}

		this.activeStep = key;
		this.navService.setActive(key, true);
		this.setLastViewedAt(key);
		this.updateWorkflowMode(this.stepper.lastStepVisible);
	}

	protected stepCompleted({ key }: StepComponent<RuntimeField>) {
		if (!key) {
			throw new Error('FormStepperComponent: step component must have key defined');
		}
		if (this.isStepValid(key)) {
			this.setSuccessAndCompleted(key);
		}
	}

	protected stepDeactivated({ key }: StepComponent<RuntimeField>) {
		if (!key) {
			throw new Error('FormStepperComponent: step component must have key defined');
		}

		const isValid = this.isStepValid(key);

		if (key.parent?.isRequired === false || isValid) {
			this.navService.setSuccess(key, isValid);
			this.setLastCompletedAt(key);
		}
	}

	protected updateWorkflowMode(stepperFinished: boolean) {
		this.workflowService.workflowMode = stepperFinished ? 'default' : 'stepper';
	}

	protected stepFailedValidation({ key }: StepComponent<RuntimeField>) {
		if (!key) {
			throw new Error('FormStepperComponent: step component must have key defined');
		}

		this.navService.setError(key, true);
	}

	private *iterateStepInfo(fields: RuntimeField[]): Iterable<{ field: RuntimeField; data: StepData | undefined }> {
		for (const field of fields) {
			if (this.workflowService.isVisible(field) && field.type === FieldType.Step) {
				yield { field, data: this.getStepData(field) };
			}

			if (field.type === FieldType.Stepper) {
				yield *this.iterateStepInfo(field.fields);
			}
		}
	}

	private setSuccessAndCompleted(field: RuntimeField) {
		this.navService.setSuccess(field, true);
		this.setLastCompletedAt(field);
	}

	private setLastViewedAt({ identifier }: RuntimeField) {
		if (!identifier) {
			throw new Error('FormStepper: Steps must have an identifier');
		}

		if (!this.scope[identifier]) {
			this.scope[identifier] = { _lastViewedAt: getUTCTime() };
		} else {
			this.scope[identifier]._lastViewedAt = getUTCTime();
		}
	}

	private setLastCompletedAt(field: RuntimeField) {
		const data = this.getStepData(field);

		if (!data) {
			throw new Error('FormStepper: _lastViewedAt must be set prior to _lastCompletedAt');
		}

		data._lastCompletedAt = getUTCTime();
	}

	private getStartingStep(viewedSteps: { field: RuntimeField; data: StepData }[]): RuntimeField | undefined {
		return viewedSteps
			.sort((a, b) => this.sortByDate(a.data._lastViewedAt, b.data._lastViewedAt))
			.pop()?.field;
	}

	private getStepData({ identifier }: RuntimeField): StepData | undefined {
		if (!identifier) {
			throw new Error('FormStepper: Steps must have an identifier');
		}

		return this.scope[identifier] as StepData | undefined;
	}

	private sortByDate(a: string, b: string): number {
		const dateA = parseFallbackISO(a, FnsDatetimeUtc);
		const dateB = parseFallbackISO(b, FnsDatetimeUtc);

		if (!dateA || !dateB) {
			throw new Error('FormStepper: unable to compare invalid dates');
		}

		return dateA > dateB ? 1 : -1;
	}

	private isStepValid(field: RuntimeField) {
		return this.scopeManager.getControl(field)?.valid === true;
	}

	private get isNavigationEnabled() {
		return this.formService.definitionSettings.isNavigationEnabled === true;
	}

}
