<sc-page-content identifier="buttons">

	<ng-container editorInputs>
		<uf-radio [options]="typeOptions" [(value)]="type" (valueChange)="modifierChange()" class="col-3" label="Type"
			columns="1" valueProperty="value" />
		<uf-radio [options]="sizeOptions" [(value)]="size" (valueChange)="modifierChange()" class="col-3" label="Sizes"
			columns="1" valueProperty="value" />
		<uf-radio [options]="colourOptions" [(value)]="colour" (valueChange)="modifierChange()" class="col-3"
			label="Colours" columns="1" valueProperty="value" />
		<uf-radio [options]="iconOptions" [(value)]="icon" (valueChange)="modifierChange()" class="col-3" label="Icon"
			columns="1" valueProperty="value" />
		<uf-radio [options]="alignmentOptions" [(value)]="alignment" (valueChange)="modifierChange()" class="col-3"
			label="Alignment" columns="1" valueProperty="value" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template">
			<div class="row justify-center">
				<a *ngIf="type === 'anchor'" [ngClass]="class" href="#">
					<uf-icon *ngIf="icon === 'left'" name="edit" class="left" />
					Button
					<uf-icon *ngIf=" icon==='right'" name=" edit" />
				</a>
				<button *ngIf="type === 'button'" [ngClass]="class" type="button">
					<uf-icon *ngIf="icon === 'left'" name="edit" class="left" />
					Button
					<uf-icon *ngIf="icon === 'right'" name="edit" />
				</button>
			</div>
		</sc-code-editor-expander>

		<h2 class="gap-top">Examples</h2>
		<div class="col-12 uf-box inset pad-lg col space-children center-all">
			<div class="row space-children max-content align-flex-end">
				<button type="button" class="uf-button primary large">Button</button>
				<button type="button" class="uf-button primary">Button</button>
				<button type="button" class="uf-button primary small">Button</button>
				<button type="button" class="uf-button primary x-small">Button
				</button>
			</div>
			<div class="row space-children max-content center-all">
				<button type="button" class="uf-button large">Button</button>
				<button type="button" class="uf-button">Button</button>
				<button type="button" class="uf-button small">Button</button>
				<button type="button" class="uf-button x-small">Button</button>
			</div>
			<div class="row space-children max-content align-flex-start">
				<button type="button" class="uf-button tertiary large">Button
				</button>
				<button type="button" class="uf-button tertiary">Button</button>
				<button type="button" class="uf-button tertiary small">Button
				</button>
				<button type="button" class="uf-button tertiary x-small">Button
				</button>
			</div>
		</div>

	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="tableConfig" [rows]="tableInfo" />
	</ng-container>

</sc-page-content>