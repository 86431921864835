<sc-page-content identifier="images">

	<ng-container editorInputs>
		<uf-radio [columns]="2" [options]="typeOptions" [(value)]="type" class="col-6" label="Type"
			valueProperty="value" />
		<uf-radio [columns]="1" [options]="imageOptions" [(value)]="image" class="col-3" label="Image Options"
			valueProperty="value" />
		<uf-radio [columns]="1" [options]="fitOptions" [(value)]="fit" class="col-3" label="Fit"
			valueProperty="value" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander class="flat">
			<div class="grow">
				<div [ngClass]="[type, fit]">
					<img src="../../../assets/img/long_reef.jpg">
				</div>
			</div>
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>