import { Component } from '@angular/core';
import { Option } from '@unifii/sdk';

import { ColumnInfo } from '../../components';

@Component({
	selector: 'sc-show-showcase-template',
	templateUrl: './show-showcase-template.html',
})
export class ShowShowcaseTemplateComponent {

	readonly options: Option[] = [
		{ name: 'Option 1', identifier: 'option1' },
		{ name: 'Option 2', identifier: 'option2' },
		{ name: 'Option 3', identifier: 'option3' },
		{ name: 'Option 4', identifier: 'option4' },
	];

	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];

	readonly cssTableInfo = [
		{ name: 'Name', type: 'Type', description: 'Property description' },
	];

	template = `<sc-page-content identifier="template">

	<ng-container editorInputs>
		<uf-radio class="col-4" label="Options" columns="1" [options]="options" valueProperty="identifier"
			[(value)]="optionValue">
		</uf-radio>
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template">
			{{template}}
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo"></sc-info-table>
	</ng-container>

</sc-page-content>`;

	optionValue = 'option1';

}
