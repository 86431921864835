<div class="uf-app-bar">
	<button (click)="runtime.close()" type="button" class="uf-action tertiary">
		<uf-icon name="close" />
	</button>
</div>
<div class="grow center-all pad">

	<ng-container *ngFor="let action of data.actions">
		<!-- Cancel actions always considered a tertiary action -->
		<button [ngClass]="action.transition.actionLabel === 'Cancel' ? 'uf-button tertiary' : 'uf-button primary'"
			(click)="runtime.close({ action })" type="button">
			{{ action.transition.actionLabel }}
		</button>
	</ng-container>

	<button *ngIf="data.cancelClickLabel" (click)="runtime.close({ cancelClicked: true })" type="button"
		class="uf-button tertiary gap-lg-top">{{data.cancelClickLabel}}</button>

</div>