<sc-page-content identifier="radio-buttons">

	<ng-container editorInputs>
		<uf-radio [options]="sizeOptions" [(value)]="size" (valueChange)="modifierChange()" class="col-3" label="Sizes"
			columns="1" valueProperty="value" />
		<uf-radio [options]="directionOptions" [(value)]="direction" (valueChange)="modifierChange()" class="col-3"
			label="Directions" columns="1" valueProperty="value" />
		<uf-radio [options]="templateOptions" [(value)]="radioTemplate" (valueChange)="modifierChange()" class="col-3"
			label="Custom options" columns="1" valueProperty="value" />
		<uf-number [(value)]="columns" (valueChange)="modifierChange()" class="col-3" label="Number of Columns" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template">
			<ng-container [ngSwitch]="radioTemplate">
				<uf-radio *ngSwitchCase="'tickCross'" [label]="label" [columns]="columns" [ngClass]="class"
					[control]="control" [direction]="direction" [columns]="columns" [options]="exampleOptions"
					[(value)]="value" (valueChange)="valueChanged($event)" style="min-width: 300px;"
					valueProperty="value" />
				<uf-radio *ngSwitchDefault [label]="label" [columns]="columns" [ngClass]="class" [control]="control"
					[direction]="direction" [columns]="columns" [options]="exampleOptions" [(value)]="value"
					(valueChange)="valueChanged($event)" style="min-width: 300px;" valueProperty="value" />
				<uf-radio *ngSwitchCase="'buttons'" [ngClass]="class" [label]="label" [control]="control"
					[options]="buttonsOptions" [direction]="direction" [(value)]="value" style="min-width: 300px;"
					boolTemplate="Button" nameProperty="name" valueProperty="value" columns="2" />
			</ng-container>

		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">Inputs</h3>
		<sc-info-table [columnConfig]="inputTableConfig" [rows]="inputTableInfo" />
		<br>
		<h3 class="pad-sides">Outputs</h3>
		<sc-info-table [columnConfig]="outputTableConfig" [rows]="outputTableInfo" />
		<br>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>