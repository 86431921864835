/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { FieldType, FormData } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { ContextProvider, ExpressionParser, ModalService, PanelComponent, RuntimeDefinition, RuntimeField, UfControlGroup } from '@unifii/library/common';
import { ComponentRegistry, FormConfiguration, FormDebugger, FormNavigationService, FormRevisionService, FormService, FormSettings, FormValidators, FormWrapper, NavigationService, ScopeManager, SmartFormServices, StateTransitionInfo, ValidatorBuilder, WorkflowService, getUTCTime } from '@unifii/library/smart-forms';

import { FormComponentRegistry } from '../../services';
import { ActionGroupModalComponent } from '../fields/modals';

/** Workflow transition context */
export interface SubmitArgs {
	// Snapshot of the data at transition applied time
	data: FormData;
	// Callback to commit the transition, won't be applied otherwise
	done: (data?: FormData) => void;
}

// Create a ComponentRegistry using the first available FormComponentRegistry in the DI
export const formComponentRegistryFactory = (formComponentRegistry: ComponentRegistry) => formComponentRegistry;

@Component({
	selector: 'uf-form',
	templateUrl: './form.html',
	styleUrls: ['./form.less'],
	providers: [
		...SmartFormServices,
		{ provide: NavigationService, useClass: FormNavigationService },
		{ provide: ComponentRegistry, useFactory: formComponentRegistryFactory, deps: [FormComponentRegistry] },
	],
})
export class UfFormComponent implements FormWrapper, OnInit, AfterViewInit, OnDestroy {

	@Input({ required: true }) definition: RuntimeDefinition;

	// Emit when the cancel button is clicked
	@Output() cancelClick = new EventEmitter<void>();
	@Output() formDataChange = new EventEmitter<FormData>();

	// Emit upon form validation, based on a transition or optional submit
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() submit = new EventEmitter<SubmitArgs>();
	@Output() edited = new EventEmitter<boolean>();

	@ViewChild(PanelComponent, { static: true }) private panelComponent: PanelComponent;
	@ViewChild('formOutlet', { read: ViewContainerRef }) private formOutletRef: ViewContainerRef;
	@ViewChild('formTemplate', { read: TemplateRef }) private formTemplateRef: TemplateRef<HTMLElement>;
	@ViewChild('headerWrapper') private headerWrapper: ElementRef<HTMLElement>;

	workflow = inject(WorkflowService);
	formService = inject(FormService);

	protected navService = inject<FormNavigationService>(NavigationService);

	private subscriptions = new Subscription();
	private rootControlSubscription?: Subscription;
	private transitionData: FormData;
	private _disabled: boolean;
	private cd = inject(ChangeDetectorRef);
	private modalService = inject(ModalService);
	private scopeManager = inject(ScopeManager);
	private expParser = inject(ExpressionParser);
	private formValidators = inject(FormValidators);
	private fieldValidators = inject(ValidatorBuilder);
	private contextProvider = inject(ContextProvider);
	private settings = inject(FormSettings);
	private revisionService = inject(FormRevisionService);
	private element = inject<ElementRef<HTMLElement>>(ElementRef);
	private formDebugger = inject(FormDebugger, { optional: true });

	private _formData: FormData;
	private viewReady = false;
	private progressId: string | undefined;

	ngOnInit() {
		this.subscriptions.add(
			this.workflow.stateChange.subscribe((info: StateTransitionInfo) => { this.workflowStateChange(info); }),
		);

		// init services and properties that don't have dependencies on the template
		this.initData();
	}

	ngAfterViewInit() {
		this.viewReady = true;

		this.initView();
	}

	@Input() set config(v: FormConfiguration) {
		this.formService.configuration = v;
	}

	get config(): FormConfiguration {
		return this.formService.configuration;
	}

	@Input() set formData(v: FormData) {
		this._formData = v;

		if (this.viewReady) {
			this.reset();
		}
	}

	get formData(): FormData {
		return this._formData;
	}

	@Input() set disabled(v: boolean) {
		if (v === this._disabled) {
			return;
		}

		this._disabled = v;

		if (this.viewReady) {
			this.reset();
		}
	}

	get disabled(): boolean {
		return this._disabled;
	}

	get rootControl(): UfControlGroup | undefined {
		return this.scopeManager.control;
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
		this.rootControlSubscription?.unsubscribe();
	}

	/** Force a refresh of form validators */
	validate() {
		this.rootControl?.setSubmitted(true);
	}

	done = (data?: FormData) => {
		this.formData = Object.assign({}, this.formData, (data ?? this.transitionData));
		this.formDataChange.emit(this.formData);

		this.deregisterInProgress();
	};

	/** Re-enable workflow in case of exception, and the method done won't be called
     */
	deregisterInProgress() {
		if (this.progressId) {
			this.formService.deregisterInProgress(this.progressId);
			this.progressId = undefined;
		}
	}

	/* Cancel action requested */
	onCancel() {
		this.cancelClick.emit();
	}

	/* Basic submit action when no workflow is available */
	onSubmit() {
		this.emitSubmit(JSON.parse(JSON.stringify(this.formData)) as FormData);
	}

	/* WorkflowService affect Section */
	isVisible(field: RuntimeField): boolean {
		return this.workflow.isVisible(field);
	}

	private initData() {
		// update form data
		this.formData._openedAt = this.formData._openedAt ?? getUTCTime();

		// Set form data on services
		this.scopeManager.scope = this.formData;

		// Order of init is important due to internal dependency of those services between them
		this.formService.init(this.definition, this.disabled);
		this.scopeManager.init(this.definition);
		this.workflow.init(this.definition, this.formData);
		this.revisionService.init(this.formData);

		// Register debugger if provided
		this.formDebugger?.register(this, this.scopeManager);

		const rootControl = this.rootControl;

		if (rootControl) {
			this.rootControlSubscription?.unsubscribe();
			this.rootControlSubscription = rootControl.valueChanges.subscribe(() => {
				this.edited.emit(rootControl.dirty);
			});
		}
	}

	private initView() {
		// Guard element.nativeElement.dateset, not available in AngularUniversal
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if ((this.element.nativeElement as HTMLElement | undefined)?.dataset) {
			this.element.nativeElement.dataset.identifier = this.definition.identifier;
		}

		if (this.definition.settings?.isNavigationEnabled) {
			this.navService.init(this.definition, this.panelComponent.scrollableContainer.nativeElement, this.headerWrapper.nativeElement.clientHeight);
		}

		this.formOutletRef.createEmbeddedView(this.formTemplateRef).detectChanges();
	}

	/* React to a requested transition */
	private workflowStateChange(info: StateTransitionInfo) {

		// Check if any ActionGroup is associated with this transition
		const actionGroup = info.source.fields.find((field) =>
			field.type === FieldType.ActionGroup &&
			field.showOn === info.formData._action &&
			this.formService.isGranted(field),
		);

		// No action group
		if (!actionGroup) {
			this.emitSubmit(info.formData);

			return;
		}

		// Show action group before emit
		void this.openActionGroup(actionGroup, info);
	}

	private async openActionGroup(actionGroup: RuntimeField, info: StateTransitionInfo) {

		const actionGroupData = await this.modalService.openLarge(
			ActionGroupModalComponent,
			{
				field: actionGroup,
				parentScopeManager: this.scopeManager,
				formData: info.formData,
			},
			undefined,
			[
				{ provide: ContextProvider, useValue: this.contextProvider },
				{ provide: ExpressionParser, useValue: this.expParser },
				{ provide: FormValidators, useValue: this.formValidators },
				{ provide: ValidatorBuilder, useValue: this.fieldValidators },
				{ provide: FormSettings, useValue: this.settings },
				{ provide: FormService, useValue: this.formService },
				{ provide: ScopeManager, useValue: this.scopeManager },
				{ provide: ComponentRegistry, useFactory: formComponentRegistryFactory, deps: [FormComponentRegistry] },
				{ provide: WorkflowService, useValue: this.workflow },
				{ provide: FormRevisionService, useValue: this.revisionService },
				{ provide: NavigationService, useValue: this.navService },
				{ provide: FormDebugger, useValue: this.formDebugger },
			],
		);

		if (actionGroupData) {
			// User completed the ActionGroup with a confirm
			this.emitSubmit(actionGroupData);
		}
	}

	private emitSubmit(data: FormData) {
		this.progressId = this.formService.registerInProgress();

		this.transitionData = data;

		this.submit.emit({
			data,
			done: this.done,
		});
	}

	private reset() {
		this.formOutletRef.clear();
		/**
		 * Notify angular that the template has been cleared immediately
		 * so any DOM calculations done later in this method are accurate
		 */
		this.cd.detectChanges();

		this.rootControlSubscription?.unsubscribe();
		this.edited.emit(false);

		this.initData();
		this.initView();
	}

}
