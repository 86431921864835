import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { Compound, FieldType } from '@unifii/sdk';
import { Subscription, first } from 'rxjs';

import { RuntimeField, StepComponent, StepperComponent, UfControlGroup } from '@unifii/library/common';
import { FieldComponent, FormContainerField, FormService, NavigationService, WorkflowService } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-form-step',
	templateUrl: './form-step.html',
	styleUrls: ['../form-group.less', './form-step.less'],
})
export class FormStepComponent implements FormContainerField, AfterViewInit, OnInit, OnDestroy {

	@ViewChild(StepComponent) stepComponent: StepComponent<RuntimeField>;

	@Input({ required: true }) field: RuntimeField;
	@Input({ required: true }) scope: Compound;
	@Input({ required: true }) control: UfControlGroup;

	protected stepControl?: UfControlGroup;
	protected isNested: boolean;
	protected identifier: string;

	private formService = inject(FormService);
	private fieldComponent = inject(FieldComponent);
	private navService = inject(NavigationService);
	private workflowService = inject(WorkflowService);
	private subscriptions = new Subscription();
	private stepperComponent = inject(StepperComponent);

	ngOnInit() {
		if (!this.field.identifier) {
			throw new Error('FormStepComponent: step fields must have an identifier');
		}
		this.identifier = this.field.identifier;
		this.isNested = this.field.parent?.parent?.type === FieldType.Stepper;

		if (this.field.parent?.isRequired) {
			this.stepControl = this.control;
		}

		if (this.isNavigationEnabled) {
			this.updateNavigationRegistration(!this.fieldComponent.hidden);

			// Subscribe to status changes to so error status can be reset when valid
			this.subscriptions.add(this.control.statusChanges.subscribe(() => {
				if (this.control.submitted) {
					this.navService.setError(this.field, this.control.invalid);
				}
			}));

			this.subscriptions.add(this.workflowService.transitionRequested.pipe(first()).subscribe(() => {
				if (this.control.invalid) {
					this.navService.setError(this.field, true);
				}
			}));
		}
	}
	
	ngAfterViewInit() {
		if (this.fieldComponent.hidden) {
			this.stepperComponent.updateStepVisibility(this.stepComponent, true);
		}

		this.subscriptions.add(this.fieldComponent.hiddenChanges.subscribe((hidden) => {
			this.stepperComponent.updateStepVisibility(this.stepComponent, hidden);
	
			if (this.isNavigationEnabled) {
				this.updateNavigationRegistration(!hidden);
			}
		}));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	isVisible(child: RuntimeField): boolean {
		return this.formService.isGranted(child);
	}

	private updateNavigationRegistration(register: boolean) {
		register ? this.navService.register(this.field) : this.navService.deregister(this.field);
	}

	private get isNavigationEnabled() {
		return this.formService.definitionSettings.isNavigationEnabled === true;
	}

}
