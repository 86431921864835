import { Component, inject } from '@angular/core';

import { ColumnGap, ModalService, Size } from '@unifii/library/common';

import { ShowModalSampleComponent } from '../../lab';

type GuardOptions = 'component' | 'guarded' | 'unguarded';
@Component({
	templateUrl: './show-modals.html',
})
export class ShowModalsComponent {

	protected readonly sizes = Size;
	protected readonly columnGaps = ColumnGap;

	protected readonly alert = `this.modal.alert('YourTitle', 'YourMessage', { cancelLabel: 'YourCancelLabel' })
	.subscribe();`;

	protected readonly confirm = `this.modal.confirm<boolean>('YourTitle', 'YourMessage', { confirmLabel: 'YourConfirmLabel', cancelLabel: 'YourCancelLabel' })
		.subscribe(response => {
	});`;

	protected readonly guardOptions: { identifier: GuardOptions; name: string }[] = [
		{ identifier: 'component', name: 'Component Guard' },
		{ identifier: 'guarded', name: 'Enabled Guard' },
		{ identifier: 'unguarded', name: 'Disabled Guard' },
	];

	protected choice: GuardOptions = 'component';

	private readonly data = {
		title: 'Modal Title',
		confirm: 'Yes',
		cancel: 'No',
	};

	private modalService = inject(ModalService);

	async openFit() {
		await this.modalService.openFit(ShowModalSampleComponent, this.data);
	}

	async openMedium(choice: GuardOptions = 'component') {
		const guard = choice === 'component' ? undefined : choice === 'guarded';

		await this.modalService.openMedium(ShowModalSampleComponent, this.data, { guard });
	}

	async openLarge() {
		await this.modalService.openLarge(ShowModalSampleComponent, this.data);
	}

	async openFullScreen() {
		await this.modalService.openFullScreen(ShowModalSampleComponent, this.data);
	}

	async openConfirm() {
		await this.modalService.openConfirm({
			title: 'Network Error',
			message: 'The network connection is lost',
			cancelLabel: 'Cancel',
			confirmLabel: 'Reconnect',
		});
	}

	async openAlert() {
		await this.modalService.openAlert({
			title: 'Login Failed',
			message: 'Your username and/or password do not match',
			confirmLabel: 'Retry',
		});
	}

}
