import { Directive, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit, Optional, Renderer2, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SortDirection, SortDirections } from '@unifii/sdk';

// Break circular dependency
import { IconComponent } from '../components/icons/icon.component';
import { SharedTermsTranslationKey } from '../translations';

import { SortDirective, Sortable } from './sort.directive';

@Directive({
	selector: '[ufSortElement]',
})
export class SortElementDirective implements Sortable, OnInit, OnDestroy {

	@HostBinding('class.sort-element') get classSort() {
		return !this.disabled;
	}

	@HostBinding('class.sort-element-active') get classSortActive() {
		return !this.disabled && this.direction != null;
	}

	@Input('ufSortElement') id: string;

	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('sortDisabled') disabled: boolean;

	private iconComponent?: IconComponent;

	constructor(
		private el: ElementRef<HTMLElement>,
		private viewContainerRef: ViewContainerRef,
		private render: Renderer2,
		private translate: TranslateService,
		@Optional() private master?: SortDirective,
	) {

		if (!master) {
			throw new Error('SortElementDirective: Master Directive is missing!');
		}
	}

	get direction(): SortDirection | null {
		if (this.master?.sort?.name === this.id) {
			return this.master.sort.direction ?? null;
		}

		return null;
	}

	ngOnInit() {

		if (!this.id) {
			throw new Error(`SortElementDirective: Identifier not specified`);
		}

		if (!this.master) {
			return;
		}

		if (!this.disabled) {
			this.master.register(this);

			this.el.nativeElement.style.cursor = 'pointer';

			const ref = this.viewContainerRef.createComponent(IconComponent);

			this.render.appendChild(this.el.nativeElement, ref.location.nativeElement);
			this.render.setAttribute(this.el.nativeElement, 'title', this.translate.instant(SharedTermsTranslationKey.ActionSort));

			this.iconComponent = ref.instance;
			this.iconComponent.el.nativeElement.classList.add('small');
			this.iconComponent.el.nativeElement.style.setProperty('margin-left', '0.5rem');
			this.iconComponent.el.nativeElement.style.setProperty('flex-shrink', '0');
			this.iconComponent.el.nativeElement.style.setProperty('opacity', '0.25');

			this.refresh();
		}
	}

	/* Postpone deregister because if meanwhile this.master is null means the SortDirective has been destoryed too
	* In this case there is no need to deregister itself and trigger the chain reaction of a sort update
	*/
	ngOnDestroy() {
		setTimeout(() => {
			if (this.master) {
				this.master.deregister(this);
			}
		}, 10);
	}

	/** Triggers the sort on this sort header and removes the indicator hint. */
	// eslint-disable-next-line @typescript-eslint/member-ordering
	@HostListener('click', ['$event'])
	click() {
		if (this.master && !this.disabled) {
			this.master.sortBy(this.id);
		}
	}

	refresh() {
		if (!this.master || !this.iconComponent) {
			return;
		}

		// Non active sort
		if (!this.master.sort || this.master.sort.name !== this.id) {

			this.iconComponent.name = 'sort';
			this.iconComponent.el.nativeElement.style.setProperty('opacity', '0.25');

			return;
		}

		// Active sort
		switch (this.master.sort.direction) {
			case null:
			case SortDirections.Descending:
				this.iconComponent.name = 'sortUp';
				break;
			case SortDirections.Ascending:
				this.iconComponent.name = 'sortDown';
				break;
		}

		this.iconComponent.el.nativeElement.style.setProperty('opacity', '1');
	}

}
