<sc-page-content identifier="actions">

	<ng-container editorInputs>

		<uf-radio [options]="typeOptions" [(value)]="type" (valueChange)="modifierChange()" class="col-3" label="Type"
			columns="1" valueProperty="value" />
		<uf-radio [options]="sizeOptions" [(value)]="size" (valueChange)="modifierChange()" class="col-3" label="Sizes"
			columns="1" valueProperty="value" />
		<uf-radio [options]="colourOptions" [(value)]="colour" (valueChange)="modifierChange()" class="col-3"
			label="Colours" columns="1" valueProperty="value" />
		<uf-radio [options]="positionOptions" [(value)]="position" (valueChange)="modifierChange()" class="col-3"
			label="Position" columns="1" valueProperty="value" />

	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template">
			<a *ngIf="type === 'anchor' && position === ''" [ngClass]="class" href="#">
				<uf-icon name="edit" />
			</a>
			<button *ngIf="type === 'button' && position === ''" [ngClass]="class" type="button">
				<uf-icon name="edit" />
			</button>
		</sc-code-editor-expander>

		<h2>Examples</h2>
		<div class="uf-box inset pad-lg col space-children center-all">
			<div class="row space-children max-content align-flex-end">
				<button type="button" class="uf-action primary large">
					<uf-icon name="edit" />
				</button>
				<button type="button" class="uf-action primary">
					<uf-icon name="edit" />
				</button>
				<button type="button" class="uf-action primary small">
					<uf-icon name="edit" />
				</button>
			</div>
			<div class="row space-children max-content center-all">
				<button type="button" class="uf-action large">
					<uf-icon name="edit" />
				</button>
				<button type="button" class="uf-action">
					<uf-icon name="edit" />
				</button>
				<button type="button" class="uf-action small">
					<uf-icon name="edit" />
				</button>
			</div>
			<div class="row space-children max-content align-flex-start">
				<button type="button" class="uf-action tertiary large">
					<uf-icon name="edit" />
				</button>
				<button type="button" class="uf-action tertiary">
					<uf-icon name="edit" />
				</button>
				<button type="button" class="uf-action tertiary small">
					<uf-icon name="edit" />
				</button>
			</div>
		</div>

	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="tableConfig" [rows]="tableInfo" />
	</ng-container>

</sc-page-content>