import { Component, Input, ViewChild, ViewContainerRef, inject } from '@angular/core';

import { DataDisplayComponentRegistry } from '../../services';

@Component({
	selector: 'uf-data-display',
	templateUrl: './data-display.html',
	styleUrls: ['./data-display.less'],
})
export class DataDisplayComponent {

	@ViewChild('container', { read: ViewContainerRef, static: true })

	private container: ViewContainerRef;
	private registry = inject(DataDisplayComponentRegistry);
	private _data: unknown;

	@Input() set data(v: unknown) {
		this._data = v;

		const transformedData = this.registry.preDataTransform ? this.registry.preDataTransform(v) : v;

		this.container.clear();
		const dataDisplayComponentType = this.registry.get(transformedData);

		if (!dataDisplayComponentType) {
			return;
		}

		const ref = this.container.createComponent(dataDisplayComponentType);
			
		ref.instance.data = transformedData;
	}

	get data(): unknown {
		return this._data;
	}

}
