<sc-page-content identifier="modals">

	<ng-container editor>
		<sc-code-editor-expander class="gap-bottom">
			<div class="row space-children justify-center">
				<button (click)="openFit()" type="button" class="uf-button">
					Open Responsive
				</button>
				<button (click)="openMedium()" type="button" class="uf-button">
					Open Medium
				</button>
				<button (click)="openLarge()" type="button" class="uf-button">
					Open Large
				</button>
				<button (click)="openFullScreen()" type="button" class="uf-button">
					Open Full Screen
				</button>
			</div>
		</sc-code-editor-expander>

		<sc-code-editor-expander class="gap-bottom">
			<uf-select [options]="guardOptions" [(value)]="choice" valueProperty="identifier" nameProperty="name">
				<uf-help
					content="Use the component internal guard or force the enabled/disabled guard from the openModal call" />
			</uf-select>
			<button (click)="openMedium(choice)" type="button" class="uf-button">
				Open with guard option
			</button>
		</sc-code-editor-expander>

		<sc-code-editor-expander [code]="alert" class="gap-bottom">
			<button (click)="openAlert()" type="button" class="uf-button">
				Open Alert
			</button>
		</sc-code-editor-expander>

		<sc-code-editor-expander [code]="confirm" class="gap-bottom">
			<button (click)="openConfirm()" type="button" class="uf-button">
				Open Confirm
			</button>
		</sc-code-editor-expander>
	</ng-container>

</sc-page-content>