<sc-page-content identifier="links">

	<ng-container editor>
		<h3 class="pad-sides">Simple Link</h3>
		<sc-code-editor-expander [code]="simpleLink">
			<p><a (click)="linkClick($event)" href="/">About Unifii</a></p>
		</sc-code-editor-expander>
		<h3 class="pad-sides">Button Link</h3>
		<sc-code-editor-expander [code]="buttonLink">
			<div class="row space-children">
				<a (click)="linkClick($event)" href="/" class="uf-button tertiary">Canel</a>
				<a (click)="linkClick($event)" href="/" class="uf-button">Save</a>
				<a (click)="linkClick($event)" href="/" class="uf-button primary">Next</a>
			</div>
		</sc-code-editor-expander>
		<h3 class="pad-sides">List Link</h3>
		<sc-code-editor-expander [code]="listLinks">
			<ul class="uf-nav-list">
				<li><a (click)="linkClick($event)" href="/">About</a></li>
				<li><a (click)="linkClick($event)" href="/">Components</a></li>
				<li><a (click)="linkClick($event)" href="/">Foundation</a></li>
			</ul>
		</sc-code-editor-expander>
	</ng-container>

</sc-page-content>