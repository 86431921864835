import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

enum ListItemTypes {
	Button = 'button',
	Item = 'item',
	Link = 'link',
	Fieldset = 'fieldset'
}

@Component({
	selector: 'sc-show-lists',
	templateUrl: './show-lists.html',
})
export class ShowListsComponent {

	protected readonly sizes = Size;
	protected readonly columnGaps = ColumnGap;

	// Table
	protected readonly tableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	protected readonly tableData = [
		{ name: 'uf-list', type: 'base class', description: 'Orders children vertically and underlines list items' },
		{ name: 'uf-list-button', type: 'base class', description: 'Aligns and space\'s button content' },
		{ name: 'uf-list-link', type: 'base class', description: 'Aligns and space\'s anchor content' },
		{ name: 'uf-list-item', type: 'base class', description: 'Aligns and space\'s content' },
	];

	// Interactive
	protected readonly boxOptions = [
		{ name: 'None (default)', value: '', classes: [] },
		{ name: 'Box', value: 'box', classes: ['uf-box'] },
		{ name: 'Flat Box', value: 'boxFlat', classes: ['uf-box', 'flat'] },
	];
	protected readonly listItemOptions = [
		{ name: 'List Button', value: ListItemTypes.Button },
		{ name: 'List Item', value: ListItemTypes.Item },
		{ name: 'List Link', value: ListItemTypes.Link },
		{ name: 'List Fieldset', value: ListItemTypes.Fieldset },
		{ name: 'Custom (default)', value: '' },
	];
	protected readonly sizeOptions = [
		{ name: 'Large', value: 'large' },
		{ name: 'Medium (default)', value: '' },
		{ name: 'Small', value: 'small' },
		{ name: 'Extra Small', value: 'x-small' },
	];
	protected readonly colorOptions = [
		{ name: 'Accent', value: 'accent' },
		{ name: 'none (default)', value: '' },
	];
	protected readonly templateSource = `
		<ul class="uf-list#class">
			#content
		</ul>
	`;

	protected readonly listItemTypes = ListItemTypes;

	protected box = '';
	protected size = '';
	protected listItem = ListItemTypes.Button;
	protected boxClass = '';
	protected template = '';
	protected color = '';

	boxChange() {

		const options = this.boxOptions.find((option) => option.value === this.box);

		if (options != null) {
			this.boxClass = options.classes.join(' ');
		} else {
			this.boxClass = '';
		}

		this.modifierChange();
	}

	modifierChange() {

		this.template = this.getTemplate();
	}

	private getTemplate(): string {

		const content = this.getContent();
		let boxClass = [this.boxClass, this.size, this.color].join(' ').trim();

		if (boxClass !== '') {
			boxClass = ' ' + boxClass;
		}

		return this.templateSource.replace('#class', boxClass).replace('#content', content);
	}

	private getContent(): string {

		switch (this.listItem) {

			case ListItemTypes.Button:
				return `<li class="uf-list-button">
							<uf-icon name="download"></uf-icon>
							<div class="multi-text">Download</div>
						</li>`;

			case ListItemTypes.Item:
				return `<li class="uf-list-item" checkboxContainer>
							<uf-checkbox [value]="true"></uf-checkbox>
							<div class="multi-text">Walter White</div>
						</li>`;

			case ListItemTypes.Link:
				return `<a class="uf-list-link">
							<div class="uf-avatar info">
								WW
							</div>
							<uf-data-display class="stacked multi-text" [items]="{ name: 'Walter White' }" />
						</a>`;

			case ListItemTypes.Fieldset:
				return `<li>
							<div class="uf-grid grow pad">
								<uf-text class="col-6" label="Username"></uf-text>
								<uf-text class="col-6" label="Email"></uf-text>
							</div>
							<div class="col center">
								<div class="uf-action tertiary">
									<uf-icon name="contextual"></uf-icon>
								</div>
							</div>
						</li>`;

			default: return `<li class="pad-left pad-right">
								<h4>Walter White</h4>
								<uf-data-display [items]="{ occupation: 'School Teacher', age: '54' }" />
								<br>
							</li>`;
		}

	}

}
