<div class="uf-app-bar">
	<h3>{{data.title}}</h3>
</div>
<div class="uf-grid pad-left pad-right gaps scrollable-section">
	<div [markdownRenderer]="data.message" class="col-12"></div>
</div>
<div class="uf-form-actions">
	<button (click)="close(false)" class="uf-button tertiary small left" type="button">
		{{data.cancelLabel}}
	</button>
	<button (click)="close(true)" class="uf-button primary small" type="button" ufAutofocus>
		{{data.confirmLabel}}
	</button>
</div>