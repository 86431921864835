import { Injectable } from '@angular/core';
import { Definition, Field, FieldType } from '@unifii/sdk';

import { FieldAdapter, RuntimeDefinition, RuntimeField, RuntimeFieldAdapter, RuntimeTransition } from '../../models';
import { fieldIterator } from '../../utils';
import { dataSourceToSourceConfig, parseDataSource } from '../data-source';
import { ExpressionParser } from '../expression-parser';

import { FieldDependenciesAdapter } from './field-dependencies-adapter';
import { getSafeRuntimeField } from './runtime-functions';
import { TransitionDependenciesAdapter } from './transition-dependencies-adapter';

@Injectable({ providedIn: 'root' })
export class RuntimeDefinitionAdapter {

	private fieldAdapters: FieldAdapter[] = [];
	private runtimeFieldAdapters: RuntimeFieldAdapter[] = [];

	constructor(expressionParser: ExpressionParser) {
		this.fieldAdapters.push(
			new FieldDependenciesAdapter(expressionParser),
			new TransitionDependenciesAdapter(expressionParser),
		);
	}

	registerAdapters(...adapters: RuntimeFieldAdapter[]) {
		this.runtimeFieldAdapters.push(...adapters);
	}

	async transform(def: Definition | RuntimeDefinition): Promise<RuntimeDefinition> {

		if ((def as RuntimeDefinition)._original as Definition | null) {
			return def as RuntimeDefinition;
		}

		const original = JSON.parse(JSON.stringify(def)) as Definition;
		const copy = JSON.parse(JSON.stringify(original)) as Definition;

		const roles: string[] = [];
		const identifierFieldMap = new Map<string, RuntimeField>();

		for (const fieldAdapter of this.fieldAdapters) {
			if (fieldAdapter.reset) {
				fieldAdapter.reset();
			}
		}

		// Iterate the fields from the root to the leaves
		for (const { field, parent } of fieldIterator(copy.fields, undefined, { order: 'preOrder' })) {
			
			// Invoked getSafeRuntimeField with preserveOriginal=false means after this function the variable field can be considered a RuntimeField
			getSafeRuntimeField(field, false);
			const runtimeField = field as unknown as RuntimeField;
			
			this.assignParent(runtimeField, parent);
			this.assignSourceConfig(field);
			this.amendSectionTransitions(field);
			this.amendVariations(field);
			this.amendShowIfs(field, parent);
			this.amendAddressAutocomplete(runtimeField);
			this.amendGeoLocationNestedFields(runtimeField);

			for (const fieldAdapter of this.fieldAdapters) {
				fieldAdapter.transform(field);
			}

			if (runtimeField.identifier) {
				identifierFieldMap.set(runtimeField.identifier, runtimeField);
			}

			roles.push(...runtimeField.roles);
			roles.push(...runtimeField.visibleTo);
			for (const transition of runtimeField.transitions) {
				roles.push(...(transition).roles);
			}

			const adaptersPromises: Promise<void>[] = [];

			for (const runtimeFieldAdapter of this.runtimeFieldAdapters) {
				const done = runtimeFieldAdapter.transform(runtimeField);

				if (done instanceof Promise) {
					adaptersPromises.push(done);
				}
			}
			if (adaptersPromises.length) {
				await Promise.all(adaptersPromises);
			}
		}

		for (const fieldAdapter of this.fieldAdapters) {
			if (fieldAdapter.done) {
				fieldAdapter.done();
			}
		}

		const runtimeDefinition = copy as unknown as RuntimeDefinition;

		runtimeDefinition._original = original;
		runtimeDefinition.fields = runtimeDefinition.fields as RuntimeField[] | null ?? [];
		runtimeDefinition.identifierFieldMap = identifierFieldMap;
		runtimeDefinition.roles = [...new Set(roles).values()];

		return runtimeDefinition;
	}

	private assignParent(field: RuntimeField, parent?: Field) {
		field.parent = parent as RuntimeField | undefined;
	}

	private assignSourceConfig(field: Field) {
		if ((field as unknown as RuntimeField).sourceConfig) {
			return;
		}

		const dataSource = parseDataSource(field.dataSourceConfig ?? field.dataSource);

		delete field.dataSource;
		delete field.dataSourceConfig;

		const sourceConfig = dataSource ? dataSourceToSourceConfig(dataSource) : undefined;

		if (sourceConfig) {
			(field as unknown as RuntimeField).sourceConfig = sourceConfig;
		} else {
			delete (field as unknown as RuntimeField).sourceConfig;
		}
	}

	private amendSectionTransitions(field: Field) {
		if (field.type !== FieldType.Section) {
			return;
		}

		field.transitions = field.transitions ?? [];

		for (const transition of field.transitions) {
			transition.tags = transition.tags ?? [];
			(transition as RuntimeTransition).roles = transition.role ? transition.role.split(',') : [];
			delete transition.role;
		}
	}

	private amendVariations(field: Field) {
		field.variations = field.variations ?? [];
		for (const variation of field.variations) {
			variation.options = variation.options ?? [];
			variation.validators = variation.validators ?? [];
		}
	}

	private amendShowIfs(field: Field, parent?: Field) {
		/**
		 * Nested stepper components don't create a field component so show if expressions need to be applied to step components
		 */
		if (field.type === FieldType.Step && parent?.showIf != null) {
			field.showIf = !field.showIf ? parent.showIf : `(${parent.showIf}) && (${field.showIf})`;
		}
	}

	private amendAddressAutocomplete(field: RuntimeField) {
		if (field.type !== FieldType.Address) {
			return;
		}

		if (field.visibleFields.includes('autocomplete')) {
			field.autocomplete = true;
			field.visibleFields = field.visibleFields.filter((key) => key !== 'autocomplete');
		} else {
			field.autocomplete = false;
		}
	}

	private amendGeoLocationNestedFields(field: RuntimeField) {
		if (field.type !== FieldType.GeoLocation) {
			return;
		}

		field.visibleFields = this.getGeoLocationNestedFieldAmendedKeys(field.visibleFields);
		field.readOnlyFields = this.getGeoLocationNestedFieldAmendedKeys(field.readOnlyFields);
		field.requiredFields = this.getGeoLocationNestedFieldAmendedKeys(field.requiredFields);
	}

	private getGeoLocationNestedFieldAmendedKeys(keys: string[]): string[] {
		return keys.includes('latlng') ?
			['lat', 'lng', ...keys.filter((identifier) => identifier !== 'latlng')] :
			keys;
	}

}
