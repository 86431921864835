<sc-page-content identifier="survey">

	<ng-container editorInputs>
		<uf-radio [options]="fieldOptions" [(value)]="fieldType" class="col-4" label="Fields" columns="1"
			valueProperty="identifier" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander *ngIf="fieldType === 'choice' && surveySingle">
			<!-- Choice -->
			<div class="grid">
				<uf-expander class="col-1of1 uf-box" actionBtn="true">
					<div expanderHeader class="uf-app-bar medium small">
						<uf-icon *ngIf="surveySingle.icon" [name]="surveySingle.icon" />
						<div class="title">
							{{surveySingle.label}}
							<uf-help *ngIf="surveySingle.help" [content]="surveySingle.help" class="grow" />
						</div>
					</div>
					<div expanderBody class="uf-grid gap-md-top">
						<span class="col-2">&nbsp;</span>
						<div [columns]="surveySingle.options.length" [cellTotal]="surveySingle.options.length" ufGrid
							direction="Row" columnGap="sm" class="col-10">
							<ng-template [ngForOf]="surveySingle.options" let-option ngFor>
								<span class="col-label">{{option.name}}</span>
							</ng-template>
						</div>
						<ng-template [ngForOf]="surveySingle.fields" let-child ngFor>
							<div class="uf-grid col-12">
								<!--
							use field component when in a smart form
							so individual inputs have access to form features like
							show if, visibleTo, autofill
						-->
								<div class="col-2 row-label">
									<span>{{child.label}}</span>
									<uf-help *ngIf="child.help" [content]="child.help" />
								</div>
								<uf-radio [options]="surveySingle.options" [columns]="surveySingle.options.length"
									class="col-10" nameProperty="name" valueProperty="identifier" direction="Row" />
							</div>
						</ng-template>
					</div>
				</uf-expander>
			</div>
		</sc-code-editor-expander>
		<sc-code-editor-expander *ngIf="fieldType === 'multiChoice' && surveyMulti">
			<!-- MultiChoice -->
			<div class="grid">
				<uf-expander class="col-1of1 uf-box" actionBtn="true">
					<div expanderHeader class="uf-app-bar medium small">
						<uf-icon *ngIf="surveyMulti.icon" [name]="surveyMulti.icon" />
						<div class="title">
							{{surveyMulti.label}}
							<uf-help *ngIf="surveyMulti.help" [content]="surveyMulti.help" class="grow" />
						</div>
					</div>
					<div expanderBody class="uf-grid gap-md-top">
						<span class="col-2">&nbsp;</span>
						<div [columns]="surveyMulti.options.length" [cellTotal]="surveyMulti.options.length" ufGrid
							direction="Row" columnGap="sm" class="col-10">
							<ng-template [ngForOf]="surveyMulti.options" let-option ngFor>
								<span class="col-label">{{option.name}}</span>
							</ng-template>
						</div>
						<ng-template [ngForOf]="surveyMulti.fields" let-child ngFor>
							<div class="uf-grid col-12">
								<span class="row-label col-2">{{child.label}}</span>
								<uf-multi-choice [options]="surveyMulti.options" [columns]="surveyMulti.options.length"
									class="col-10" nameProperty="name" valueProperty="identifier" direction="Row">
									<uf-help *ngIf="child.help" [content]="child.help" />
								</uf-multi-choice>
							</div>
						</ng-template>
					</div>
				</uf-expander>
			</div>
		</sc-code-editor-expander>
	</ng-container>

</sc-page-content>