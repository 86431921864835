import { Component, Input } from '@angular/core';

import { ModalService } from '../../../services';
import { HelpModalComponent } from '../../modal';

@Component({
	selector: 'uf-help',
	templateUrl: './help.html',
	styleUrls: ['./help.less'],
})
export class HelpComponent {

	@Input() icon = 'info';
	@Input() content?: string | null;

	constructor(private modalService: ModalService) { }

	open() {
		void this.modalService.openFit(HelpModalComponent, this.content);
	}

}
