<sc-page-content identifier="text-fields">

	<ng-container editorInputs>
		<uf-radio [options]="componentOptions" [(value)]="component" (valueChange)="modifierChange()" class="col-6"
			label="Component" columns="1" valueProperty="identifier" />
		<uf-radio [options]="sizeOptions" [(value)]="size" (valueChange)="modifierChange()" class="col-6" label="Sizes"
			columns="1" valueProperty="identifier" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template">
			<uf-text *ngIf="component === 'uf-text'" [ngClass]="size" style="width: 300px" label="Text" />
			<uf-textarea *ngIf="component === 'uf-textarea'" [ngClass]="size" style="width: 300px" label="Text Area" />
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">Inputs</h3>
		<h4 class="pad-sides">uf-text, uf-textarea</h4>
		<sc-info-table [columnConfig]="inputTableConfig" [rows]="inputTableInfo" />
		<h4 class="pad-sides">uf-text</h4>
		<sc-info-table [columnConfig]="inputTableConfig" [rows]="textInputTableInfo" />
		<h3 class="pad-sides">Outputs</h3>
		<sc-info-table [columnConfig]="outputTableConfig" [rows]="outputTableInfo" />
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>