export * from './alert-modal.component';
export * from './confirm-modal.component';
export * from './content-modal.component';
export * from './help-modal.component';
export * from './list-box.component';
export * from './map-viewer-modal.component';

import { AlertModalComponent } from './alert-modal.component';
import { ConfirmModalComponent } from './confirm-modal.component';
import { ContentModalComponent } from './content-modal.component';
import { HelpModalComponent } from './help-modal.component';
import { ListBoxComponent } from './list-box.component';
import { MapViewerModalComponent } from './map-viewer-modal.component';

export const UF_MODAL_MODALS_COMPONENTS = [AlertModalComponent, ConfirmModalComponent, ContentModalComponent, HelpModalComponent, ListBoxComponent, MapViewerModalComponent];
