<sc-page-content identifier="palette">

	<ng-container editor>
		<table #example class="uf-table accent">
			<thead>
				<tr>
					<th>Color</th>
					<th>Name</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let color of colors">
					<td>
						<div [ngClass]="color.class" [title]="color.name" class="uf-color-swatch col-3 large"></div>
					</td>
					<td>
						{{color.name}}
					</td>
				</tr>
			</tbody>
		</table>
	</ng-container>

</sc-page-content>