<div class="uf-app-bar">
	<button [title]="data.closeButtonLabel" (click)="close()" type="button" class="uf-action tertiary">
		<uf-icon name="close" />
	</button>
	<h3>{{data.title}}</h3>
</div>
<div class="uf-grid pad-left pad-right gaps scrollable-section">
	<div [markdownRenderer]="data.message" class="col-12"></div>
</div>
<div class="uf-form-actions">
	<button (click)="close()" class="uf-button primary small" type="button" ufAutofocus>
		{{data.confirmLabel}}
	</button>
</div>