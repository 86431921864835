<sc-page-content identifier="reactive-forms">

	<ng-container editor>
		<div [formGroup]="form" class="col-12">
			<uf-password-advanced *ngIf="!hide" [formControlName]="controlKeys.Control" label="test" />
			<uf-password-indicator [formControlName]="controlKeys.Control" />
			<p>{{ form.value | json }}</p>
		</div>
		<div class="col-12">
			<button (click)="setSubmitted()" type="button">Submit</button>
			<button (click)="hide = !hide" type="button">Submit</button>
		</div>
	</ng-container>

</sc-page-content>