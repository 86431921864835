<sc-page-content identifier="cards">

	<ng-container editorInputs>
		<uf-radio [options]="typeOptions" [(value)]="type" class="col-6" label="Type" columns="1"
			valueProperty="value" />
		<uf-radio [options]="layoutOptions" [(value)]="layout" class="col-6" label="Layout" columns="1"
			valueProperty="value" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander class="demo">
			<a *ngIf="type === types.Anchor" [ngClass]="cssClass" class="uf-card">
				<div class="uf-img-2x1 cover">
					<img src="/assets/img/long_reef.jpg">
				</div>
				<div class="uf-card-title">
					<h4>Long Reef Beach</h4>
				</div>
				<div class="uf-grid pad-right pad-left">
					<uf-data-display-list [items]="{ state: 'NSW', rating: '5 Stars'}" class="col-12 small" />
					<br>
				</div>
			</a>
			<div *ngIf="type === types.Container" [ngClass]="cssClass" class="uf-card">
				<!-- todo: add to static examples -->
				<!-- <div class="uf-app-bar">
					<button class="uf-action tertiary">
						<uf-icon name="contextual"></uf-icon>
					</button>
					<h3 *ngIf="selectedElements[elements.Heading]">Long Reef Beach</h3>
				</div> -->
				<!-- <div class="uf-grid pad">
					<div class="col-12">
						<uf-data-display-list *ngIf="selectedElements[elements.Content]"
							[items]="{ state: 'NSW', rating: '5 Stars'}">
						</uf-data-display-list>
					</div>
				</div> -->
				<div class="pad-left pad-right">
					<h4>Long Reef Beach</h4>
					<uf-data-display-list [items]="{ state: 'NSW', rating: '5 Stars'}" class="small" />
				</div>
				<div class="uf-form-actions">
					<button type="button" class="uf-button primary small">Learn more</button>
				</div>
			</div>
			<div *ngIf="type === types.Modal" [ngClass]="cssClass" class="uf-card">
				<button type="button" class="uf-action tertiary float-right" title="Close">
					<uf-icon name="close" />
				</button>
				<div class="pad-left pad-right">
					<h4>Long Reef Beach</h4>
					<uf-data-display-list [items]="{ state: 'NSW', rating: '5 Stars'}" class="small" />
				</div>
				<div class="uf-form-actions">
					<button type="button" class="uf-button primary small">Learn more</button>
				</div>
			</div>
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>