<div class="uf-app-bar">
	<button [title]="sharedTermsTK.ActionClose | translate" (click)="close()" ufAutofocus class="uf-action tertiary"
		type="button">
		<uf-icon name="close" />
	</button>
	<h3>{{commonTK.AdvancedSearchLabel | translate }}</h3>
</div>

<div class="uf-grid pad-left pad-right gaps scrollable-section">
	<uf-filter-display *ngIf="data.filterManager" [manager]="data.filterManager" [value]="data.filterValues"
		(valueChange)="applyFilters()" class="col-12" />
	<uf-autocomplete [options]="options" [minSearchLength]="data.minSearchLength" [(value)]="value"
		(searchChange)="search($event)" nameProperty="_display" class="col-12 margin--vertical">
		<ng-container actions>
			<div class="input-action-btn">
				<button [title]="commonTK.FiltersLabel | translate" (click)="openFilters()" class="uf-action tertiary"
					type="button">
					<uf-icon name="filter" />
				</button>
			</div>
		</ng-container>
		<ng-template #listBox let-options="options" let-select="select" let-active="active">
			<ul class="uf-list">
				<li *ngFor="let option of options; let i = index" [class.active]="i === active" (click)="select(option)"
					class="uf-list-button block">
					<ng-template [ngIf]="option._customOption || option._notSelectable" [ngIfElse]="dataSeed">
						<span *ngIf="option._notSelectable">{{option.name}}</span>
						<span *ngIf="!option._notSelectable" (click)="select(option)">{{option.name}}</span>
					</ng-template>
					<ng-template #dataSeed>
						<uf-data-seed-mappings-display [sourceConfig]="data.sourceConfig" [dataSeed]="option"
							[showDisplayVisibleField]="true" class="col-12" />
					</ng-template>
				</li>
			</ul>
		</ng-template>
	</uf-autocomplete>
	<uf-data-seed-mappings-display [sourceConfig]="data.sourceConfig" [dataSeed]="value" class="col-12" />
</div>

<div class="uf-form-actions">
	<button (click)="close()" class="uf-button tertiary small right" type="button">
		{{ sharedTermsTK.ActionCancel | translate }}
	</button>
	<button (click)="select()" class="uf-button primary small" type="button">
		{{ sharedTermsTK.ActionSelect | translate }}
	</button>
</div>