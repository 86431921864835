<sc-page-content identifier="description-list">

	<ng-container editorInputs>
		<uf-select [options]="sizeOptions" [(value)]="size" (valueChange)="modifierChange()" class="col-6 col-md-3"
			label="Sizes" valueProperty="value" />
		<uf-select [options]="layoutOptions" [(value)]="layout" (valueChange)="modifierChange()" class="col-6 col-md-3"
			label="Layout" valueProperty="value" />
		<uf-select [options]="colourOptions" [(value)]="textColour" (valueChange)="modifierChange()"
			class="col-6 col-md-3" label="Text colour" valueProperty="value" />
		<uf-select [options]="rowGapOptions" [(value)]="rowGap" (valueChange)="modifierChange()" class="col-6 col-md-3"
			label="Row Gap" valueProperty="identifier" />
		<uf-checkbox [(value)]="debug" class="col-6 col-md-6" label="Debug (edge cases)" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template">
			<uf-data-display-list [items]="!debug ? value : debugValue" [ngClass]="[size, layout, textColour, rowGap]"
				style="width: 100%" />
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3>Interfaces</h3>
		<pre class="uf-box inset pad-lg">
DescriptionListItem
	term?: string;
	help?: string; // rendered as markdown
	description: Description[];
		</pre>
		<br>
		<h3>Properties</h3>
		<sc-info-table [columnConfig]="pairPropertiesColConfig" [rows]="pairPropertiesRows" />
		<br>
		<h3>CSS Classes</h3>
		<sc-info-table [columnConfig]="pairCssTableConfig" [rows]="pairCss" />
	</ng-container>

</sc-page-content>