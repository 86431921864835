import { EventEmitter, Injectable, OnDestroy, inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FieldType, FormData } from '@unifii/sdk';
import { Subject, Subscription } from 'rxjs';

import { ContextProvider, ExpressionFunction, ExpressionParser, RuntimeDefinition, RuntimeField, RuntimeTransition,
	UfControlGroup, UfExpressionFunctionsSet } from '@unifii/library/common';

import { DebugValidation } from '../constants';
import { FormSettings, StateTransitionInfo, WorkflowAction } from '../models';
import { FieldHelperFunctions, FormFunctions } from '../utils';

import { FormDebugger } from './form-debugger';
import { FormService } from './form.service';
import { ScopeManager } from './scope-manager';

interface StaticWorkflowAction {
	action: WorkflowAction;
	visible: boolean;
	func?: ExpressionFunction;
}

/* UfFormComponent private instance */
@Injectable()
export class WorkflowService implements OnDestroy {

	definition?: RuntimeDefinition;
	actions: WorkflowAction[] = [];
	transitionRequested = new Subject<void>(); // Notify subscribers when a transition has been request so additional messaging can be passed on to the user if required.
	stateChange = new EventEmitter<StateTransitionInfo>();
	updated = new EventEmitter<FormData>();

	private formData?: FormData;
	private subscriptions = new Subscription();
	private staticActions: StaticWorkflowAction[] = [];
	private visibleSections = new Set<RuntimeField>();
	private scopeManager = inject(ScopeManager);
	private expParser = inject(ExpressionParser);
	private formService = inject(FormService);
	private contextProvider = inject(ContextProvider);
	private formSettings = inject(FormSettings);
	private formDebugger = inject(FormDebugger, { optional: true });
	private _actionsPosition: 'default' | 'floating' = 'default';
	private _workflowMode: 'stepper' | 'default' = 'default';

	get actionPosition() {
		return this._actionsPosition;
	}

	set actionPosition(v: 'default' | 'floating') {
		if (this._actionsPosition === v) {
			return;
		}

		this._actionsPosition = v;

		this.updateVisibleActions();
	}

	get workflowMode() {
		return this._workflowMode;
	}

	set workflowMode(v: 'stepper' | 'default') {
		if (this._workflowMode === v) {
			return;
		}

		this._workflowMode = v;

		this.updateVisibleActions();
	}

	// Debugging
	get isWorkflowEnabled(): boolean {
		return !this.formDebugger || this.formDebugger.enableWorkflow;
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	init(definition: RuntimeDefinition, formData: FormData) {
		this.definition = definition;
		this.formData = formData;

		this.visibleSections = new Set();

		for (const section of this.definition.fields.filter((f) => f.type === FieldType.Section)) {
			if (this.isSectionActive(section, this.formData)) {
				this.visibleSections.add(section);
				continue;
			}

			if (this.isSectionHistorical(section, this.formData)) {
				this.visibleSections.add(section);
			}

			if (this.isWorkflowEnabled) {
				// Disable all controls which are historical or have not been rendered
				this.scopeManager.getControl(section)?.disable();
			}
		}

		this.updateStaticActions(this.definition, this.formData);

		this.updateVisibleActions();

		this.updated.emit(this.formData);
	}

	validate(transition?: RuntimeTransition, sourceSection?: RuntimeField): boolean {

		if (transition && transition.source !== this.formData?._state) {
			console.warn('Source state does not match!', transition.source, this.formData?._state);

			return false;
		}
		
		const ignoreRequired = (transition && transition.validate === false) && !this.useDebuggerValidators;
		const activeControl = sourceSection ? this.scopeManager.getControl(sourceSection) as UfControlGroup : this.scopeManager.control;
		const isValid = ignoreRequired ? this.scopeManager.validateExcludingRequired(activeControl) : activeControl.valid;

		if (!isValid) {
			activeControl.setSubmitted();
		}

		this.transitionRequested.next();

		return isValid;
	}

	/** Return true if the transition is executed */
	trigger(workflowAction: WorkflowAction): boolean {
		if (!this.formData) {
			throw Error('WorkflowService: action triggered without service initialized');
		}

		if (this.formService.disabled || this.formService.inProgress) {
			return false;
		}

		const transition = workflowAction.transition;
		const section = workflowAction.source;

		if (!this.validate(transition, section)) {
			return false;
		}

		const next = this.createNextFormData(this.formData, transition);

		this.stateChange.emit({ formData: next, source: section, transition });

		return true;
	}

	isVisible(field: RuntimeField): boolean {

		// Static visibility (Section can ignore if when already executed)
		if (!this.formService.isGranted(field) && field.type !== FieldType.Section) {
			return false;
		}

		// No Workflow, apply static visibility
		if (!this.isWorkflowEnabled) {
			return this.formService.isGranted(field);
		}

		// Workflow enabled
		if (field.type === FieldType.Section) {
			return this.visibleSections.has(field);
		}

		return this.formService.isGranted(field);
	}

	isActive(field: RuntimeField): boolean {
		if (this.formSettings.print || this.formService.disabled) {
			return false;
		}

		if (!this.isWorkflowEnabled) {
			return true;
		}

		const section = this.getParentSection(field);

		return section ? section.transitions.some((transition) => this.formData?._state === transition.source) : true;
	}

	private updateVisibleActions() {
		this.actions = this.getActions().filter(({ transition }) => {
			return (this.workflowMode === 'default' && this.actionPosition === 'default') || !!transition.hasPersistentVisibility;
		});
	}

	private getActions() {
		if (!this.isWorkflowEnabled || this.formSettings.print) {
			return [];
		}

		return this.staticActions
			.filter((sa) => sa.visible)
			.map((sa) => sa.action)
			.reduce((unique: WorkflowAction[], action) => {

				const transitionAction = action.transition.action;
				const transitionTarget = action.transition.target;
				const dupeIndex = unique.findIndex((u) => u.transition.action === transitionAction && u.transition.target === transitionTarget);

				if (dupeIndex < 0) {
					unique.push(action);
				} else if (action.source.fields.find((f) => f.type === FieldType.ActionGroup) != null) {
					// Overwrite action with action with action group to ensure it action groups are triggered
					// todo - find a better solution as the last one will win if there are multiple action groups in multiple active sections
					unique[dupeIndex] = action;
				}

				return unique;
			}, []);
	}

	private isSectionActive(section: RuntimeField, formData: FormData): boolean {
		return this.formService.isGranted(section) &&
			// filter transitions by matching role to user
			section.transitions
				.filter((t) => FieldHelperFunctions.areRolesMatching(this.roles, t.roles))
			// Check source state matches current state
				.some((t) => t.source === formData._state);
	}

	private isSectionHistorical(section: RuntimeField, formData: FormData): boolean {
		return section.transitions
			.some((t) => (formData._history ?? [])
				.some((h) => h.state === t.source && h.action === t.action));
	}

	private getParentSection(field: RuntimeField): RuntimeField | undefined {

		if (field.type === FieldType.Section) {
			return field;
		}

		const parent = field.parent;

		if (!parent) {
			return;
		}

		return parent.type === FieldType.Section ?
			parent :
			this.getParentSection(parent);
	}

	private get roles(): string[] {
		const user = this.contextProvider.get().user;

		return (user ? user.roles : []) ?? [];
	}

	private get useDebuggerValidators(): boolean {
		return !!this.formDebugger &&
			[DebugValidation.Off, DebugValidation.ExcludeRequired].includes(this.formDebugger.validation);
	}

	private updateStaticActions(definition: RuntimeDefinition, formData: FormData) {
		this.subscriptions.unsubscribe();
		this.subscriptions = new Subscription();

		// Clean previous actions
		this.staticActions = [];

		// Actions wrappers with showIf function/listener
		this.staticActions = this.getCurrentWorkflowActions(definition, formData)
			.map((action) => this.createStaticAction(action, () => {
				this.actions = this.getActions();
			}));
	}

	private createStaticAction(action: WorkflowAction, onVisibilityChange: () => any): StaticWorkflowAction {

		const staticAction: StaticWorkflowAction = {
			action,
			visible: true,
		};

		if (!action.transition.showIf) {
			return staticAction;
		}

		try {
			staticAction.func = this.expParser.getFunc(action.transition.showIf) ?? undefined;
		} catch (e) {
			console.warn(`Parse transition '${action.transition.source} > ${action.transition.target} [${action.transition.action}]' showIf '${action.transition.showIf}'`, e);

			return staticAction;
		}

		const fields = action.transition.dependencies;
		const controls = fields.map((f) => this.scopeManager.getControl(f)).filter((v) => v) as AbstractControl[];

		for (const control of controls) {
			const evaluateVisibilityOnChange = (value?: any) => {

				const result = this.evaluateExpression(staticAction, value);

				if (staticAction.visible !== result) {
					staticAction.visible = result;
					onVisibilityChange();
				}
			};

			/**
			 * Control status change listeners used instead of registering OnValueUpdate because we require
			 * bindings to run before evaluating expression // for readonly case?
			 */
			this.subscriptions.add(control.statusChanges.subscribe(() => { evaluateVisibilityOnChange(); },
			));

			this.subscriptions.add(control.valueChanges.subscribe(() => { evaluateVisibilityOnChange(); },
			));
		}
		// Immediate visibility update for this action
		staticAction.visible = this.evaluateExpression(staticAction, null);

		return staticAction;
	}

	private evaluateExpression = (staticAction: StaticWorkflowAction, value?: any): boolean => {

		if (!staticAction.func) {
			return true;
		}

		try {
			const context = this.scopeManager.createContext(value);

			return staticAction.func(this.scopeManager.scope, context, UfExpressionFunctionsSet) as unknown as boolean;
		} catch (e) {
			console.warn(`Execute '${staticAction.action.transition.source} > ${staticAction.action.transition.target} [${staticAction.action.transition.action}]' showIf '${staticAction.action.transition.showIf}'`, e);

			return true;
		}
	};

	private getCurrentWorkflowActions(definition: RuntimeDefinition, formData: FormData): WorkflowAction[] {
		// Filter actions based on formData._state
		return definition.fields
		// Needed only Section(s) matching current user role with defined Transition(s)
			.filter((field) =>
				field.type === FieldType.Section
				&& FieldHelperFunctions.areRolesMatching(this.roles, field.roles)
				&& field.transitions.length,
			)
		// Filter Transition(s) matching current form state and user roles and transform to WorkflowAction(s)
			.map((field) =>
				field.transitions
					.filter((t) =>
						t.source === formData._state
						&& FieldHelperFunctions.areRolesMatching(this.roles, t.roles),
					)
					.map((transition) => ({ transition, source: field })),
			)
		// Flat all WorkflowAction(s) in an array and return
			.reduce((result, current) => {
				result.push(...current);

				return result;
			}, []);
	}

	private createNextFormData(formData: FormData, transition: RuntimeTransition): FormData {

		const user = this.contextProvider.get().user;

		// Return a copy of the formData to avoid changes before transition is completed by done() callback
		const next = JSON.parse(JSON.stringify(formData)) as FormData;

		// Metadata managed by client side
		next._history = next._history ?? [];
		next._history.unshift({ state: formData._state!, action: transition.action });
		next._action = transition.action;
		next._state = transition.target;
		next._result = transition.result;
		next._completedAt = FormFunctions.getUTCTime();

		// Metadata managed by server side
		// Those get updated by client side as temporary values before server side save occurs
		// To cover missing server side save when data is saved only in offline queue
		if (user) {
			next._lastModifiedBy = user.username;
			next._createdBy = next._createdBy ?? user.username;
		}

		return next;
	}

}
