<div class="uf-app-bar">
	<button [title]="sharedTermsTK.ActionClose | translate" (click)="close()" ufAutofocus class="uf-action tertiary"
		type="button">
		<uf-icon name="close" />
	</button>
	<h3>{{commonTK.FiltersLabel | translate }}</h3>
</div>

<div class="uf-grid pad-left pad-right gaps scrollable-section">
	<uf-filter-inputs *ngIf="data.filterManager" [manager]="data.filterManager" [value]="localFilters" class="col-12" />
</div>

<div class="uf-form-actions">
	<button (click)="close()" type="button" class="uf-button tertiary small right">
		{{ sharedTermsTK.ActionCancel | translate }}
	</button>
	<button (click)="select()" type="button" class="uf-button primary small">
		{{ sharedTermsTK.ActionSelect | translate }}
	</button>
</div>