"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildIfMatchHeaderValue = exports.getEtag = exports.composeDownloadUrl = exports.amendOptionsParams = exports.mergeParams = exports.purgeParams = exports.mergeHeaders = exports.appendParamsToUrl = exports.getErrorType = exports.getError = exports.checkResponse = exports.getBody = void 0;
const http_status_codes_1 = require("http-status-codes");
const client_models_1 = require("../client-models");
const client_types_1 = require("../client-types");
const constants_1 = require("../constants");
const models_1 = require("../models");
const query_functions_1 = require("./query-functions");
const type_guard_functions_1 = require("./type-guard-functions");
const getBody = async (response) => {
    if (!response?.headers) {
        return;
    }
    const contentType = response.headers.get(client_types_1.HeaderKeys.ContentType);
    if (!contentType) {
        return;
    }
    if (contentType.includes(client_models_1.ClientContentType.ApplicationJson)) {
        // There isn't a better way to detect empty body yet
        // https://github.com/angular/angular/issues/6735
        // Try this out https://mcculloughwebservices.com/2016/09/23/handling-a-null-response-from-an-api/
        try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return await response.json();
        }
        catch (error) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (error.code === DOMException.ABORT_ERR) {
                throw error;
            }
            console.warn('Response.json() threw on empty body');
            return null;
        }
    }
    if (contentType.includes(client_models_1.ClientContentType.ApplicationPdf)) {
        return response.blob();
    }
    return response.text();
};
exports.getBody = getBody;
const checkResponse = async (response) => {
    if (response.ok) {
        return response;
    }
    throw await (0, exports.getError)(response);
};
exports.checkResponse = checkResponse;
const getError = async (response) => {
    const status = response.status;
    const type = (0, exports.getErrorType)(status);
    let message = (0, http_status_codes_1.getReasonPhrase)(status);
    let data;
    try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data = await (0, exports.getBody)(response);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
        message = data?.message ?? message;
    }
    catch (e) { /**/ }
    return new models_1.UfRequestError(message, type, data, status);
};
exports.getError = getError;
const getErrorType = (status) => {
    if (status >= 500) {
        return client_models_1.ErrorType.Server;
    }
    switch (status) {
        case http_status_codes_1.StatusCodes.BAD_REQUEST: return client_models_1.ErrorType.Validation;
        case http_status_codes_1.StatusCodes.UNAUTHORIZED: return client_models_1.ErrorType.Unauthorized;
        case http_status_codes_1.StatusCodes.FORBIDDEN: return client_models_1.ErrorType.Forbidden;
        case http_status_codes_1.StatusCodes.NOT_FOUND: return client_models_1.ErrorType.NotFound;
        case http_status_codes_1.StatusCodes.CONFLICT: return client_models_1.ErrorType.Conflict;
        default: return client_models_1.ErrorType.Unknown;
    }
};
exports.getErrorType = getErrorType;
const appendParamsToUrl = (url, params, query) => {
    if (params && Object.keys(params).length) {
        const searchParams = new URLSearchParams();
        for (const key of Object.keys(params)) {
            const paramValue = params[key];
            if (paramValue != null && (0, type_guard_functions_1.isString)(paramValue) || (0, type_guard_functions_1.isNumber)(paramValue) || (0, type_guard_functions_1.isBoolean)(paramValue)) {
                searchParams.set(key, `${paramValue}`);
            }
        }
        return `${url}?${searchParams.toString()}`;
    }
    if (query) {
        return `${url}?${query}`;
    }
    return url;
};
exports.appendParamsToUrl = appendParamsToUrl;
const mergeHeaders = (...headers) => {
    const result = {};
    for (const source of headers) {
        if (source == null || typeof source !== 'object') {
            continue;
        }
        const sourceHeaders = new Headers(source);
        sourceHeaders.forEach((value, key) => {
            if (value !== 'undefined') {
                result[key] = value;
            }
        });
    }
    return new Headers(result);
};
exports.mergeHeaders = mergeHeaders;
/**
 * Return a valorized params object without empty entries, doesn't modify the input
 *
 * @param params the params
 * @returns a purged and valorized params object
 */
const purgeParams = (params) => {
    const result = {};
    if (!params) {
        return result;
    }
    for (const key of Object.keys(params).filter((k) => params[k] != null)) {
        result[key] = params[key];
    }
    return result;
};
exports.purgeParams = purgeParams;
/**
 * Return a valorized params object result of the merged inputs, doesn't modify the inputs
 *
 * @param params array of params to merge into a new params object
 * @returns a purged and merged params object
 */
const mergeParams = (...params) => {
    const result = {};
    for (const entry of params.map(exports.purgeParams)) {
        for (const key of Object.keys(entry)) {
            result[key] = entry[key];
        }
    }
    return result;
};
exports.mergeParams = mergeParams;
/**
 * Return a request options object with amended params entries in case they are missing from the input options
 * Doesn't modify the inputs
 *
 * @param params params entries to amend in the options
 * @param options target to amend the params into
 * @returns a new options object with amended params
 */
const amendOptionsParams = (params, options) => {
    const result = { ...options };
    result.params = (0, exports.mergeParams)(params, result.params);
    return result;
};
exports.amendOptionsParams = amendOptionsParams;
const composeDownloadUrl = (url, format, query) => {
    let result = `${url}?${constants_1.QueryParams.Format}=${format}`;
    const stringifiedQuery = (0, query_functions_1.stringifyQuery)(query);
    if (stringifiedQuery) {
        result += `&${stringifiedQuery}`;
    }
    return result;
};
exports.composeDownloadUrl = composeDownloadUrl;
/**
 * Extract the etag value from its double quote enclosure
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/ETag
 * @param headers
 */
const getEtag = (headers) => {
    const enclosedEtag = headers.get(client_types_1.HeaderKeys.Etag);
    if (!enclosedEtag) {
        return;
    }
    let etag = enclosedEtag.replace(/"/g, '');
    // regex to test that the string start with (case-sensitive) W/
    const weakRegex = /^(W\/)/gm;
    const weak = weakRegex.test(etag);
    if (weak) {
        etag = etag.replace(weakRegex, '');
    }
    if (!etag.length) {
        return;
    }
    return { etag, weak };
};
exports.getEtag = getEtag;
/**
 * Prepare a W3C compliant If-Match header value
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/If-Match
 * @param revisions
 */
const buildIfMatchHeaderValue = (revisions) => {
    if (!revisions?.length) {
        return;
    }
    return revisions
        .filter((r) => r != null)
        .map((r) => /^["](.*["])?$/gm.test(r) ? r : `"${r}"`)
        .join(', ');
};
exports.buildIfMatchHeaderValue = buildIfMatchHeaderValue;
