<sc-page-content identifier="body-copy">
	<ng-container editor>
		<uf-tabs (selectedChange)="updateSize()">
			<uf-tab label="Example">
				<div #bodyCopyElement [markdownRenderer]="markdown" class="pad-sides gap-top body-copy"></div>
			</uf-tab>
			<uf-tab label="Style">
				<sc-info-table [columnConfig]="tableConfig" [rows]="rows" />
			</uf-tab>
			<uf-tab label="Markdown">
				<uf-markdown-editor [(value)]="markdown" />
			</uf-tab>
		</uf-tabs>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>
</sc-page-content>