import { Injectable, inject } from '@angular/core';
import { ProjectContentOptionsInterface, ProjectInfo, TokenStorage, UserInfo, decrypt, encrypt } from '@unifii/sdk';

import { Repository, StorageWrapper } from '@unifii/library/common';

import { Environment } from '../models';

export enum StorageKeys {
	AccessToken = 'UfToken',
	RefreshToken = 'UfRefreshToken',
	ExpiresAt = 'UfExpiresAt',
	Company = 'UfCompany',
	BaseURL = 'UfBaseUrl',
	User = 'UfUser',
	Project = 'UfProject',
	Preview = 'UfPreview',
	Offline = 'UfOffline',
	FormId = 'UfFormId',
	Language = 'UfLanguage',
	MinSearchLength = 'UfMinSearchLength'
}

@Injectable({ providedIn: 'root' })
export class ShowcaseRepositoryService implements TokenStorage, ProjectContentOptionsInterface {

	// Refresh token crypto key cached
	private refreshTokenCache: CryptoKey;
	private repository = inject(Repository);
	private config = inject(Environment);
	private storage = inject(StorageWrapper);

	get company(): string | null {
		return this.repository.loadString(StorageKeys.Company);
	}

	set company(value: string | null) {
		this.repository.storeString(StorageKeys.Company, value);
	}

	get baseUrl(): string | null {
		return this.repository.loadString(StorageKeys.BaseURL);
	}

	set baseUrl(value: string | null) {
		this.repository.storeString(StorageKeys.BaseURL, value);
	}

	get token(): string | null {
		return this.repository.loadString(StorageKeys.AccessToken);
	}

	set token(value: string | null) {
		this.setStorageItem(StorageKeys.AccessToken, value);
	}

	set expiresAt(value: string | null) {
		this.setStorageItem(StorageKeys.ExpiresAt, value);
	}

	get expiresAt(): string | null {
		return this.repository.loadString(StorageKeys.ExpiresAt);
	}

	get user(): UserInfo | null {
		return this.repository.load<UserInfo>(StorageKeys.User);
	}

	set user(value: UserInfo | null) {
		this.repository.store(StorageKeys.User, value);
	}

	get project(): ProjectInfo | null {
		return this.repository.load<ProjectInfo>(StorageKeys.Project);
	}

	set project(value: ProjectInfo | null) {
		this.repository.store(StorageKeys.Project, value);
	}

	get projectId(): string {
		return this.project?.id ?? '';
	}

	get offline(): boolean {
		return this.repository.load<boolean>(StorageKeys.Offline) ?? false;
	}

	set offline(value: boolean) {
		this.repository.store(StorageKeys.Offline, value);
	}

	get formId(): number | null {
		const formId = this.repository.loadString(StorageKeys.FormId);

		if (!formId) {
			return null;
		}

		return parseInt(formId);
	}

	set formId(value: number | null) {
		this.repository.storeString(StorageKeys.FormId, `${value}`);
	}

	set preview(value: boolean) {
		this.repository.store(StorageKeys.Preview, value);
	}

	get preview(): boolean {
		return this.repository.load<boolean>(StorageKeys.Preview) ?? false;
	}

	set language(value: string) {
		this.repository.storeString(StorageKeys.Language, value);
	}

	get language(): string | null {
		return this.repository.loadString(StorageKeys.Language);
	}

	set minSearchLength(value: number) {
		this.repository.store(StorageKeys.MinSearchLength, value);
	}

	get minSearchLength() {
		return this.repository.load<number>(StorageKeys.MinSearchLength) ?? 2;
	}

	async setRefreshToken(v: string | null) {

		if (!v) {
			this.setStorageItem(StorageKeys.RefreshToken, null);

			return;
		}

		const { byteString, cryptoKey } = await encrypt(this.encryptionKey, v);

		this.refreshTokenCache = cryptoKey;
		this.setStorageItem(StorageKeys.RefreshToken, byteString);

	}

	getRefreshToken(): Promise<string | null> {

		const byteString = this.repository.loadString(StorageKeys.RefreshToken);

		if (!byteString) {
			return Promise.resolve(null);
		}

		return decrypt(
			{
				key: this.encryptionKey ?? undefined,
				cryptoKey: this.refreshTokenCache,
				byteString,
			});
	}

	reset() {
		this.repository.clear();
	}

	private get encryptionKey() {
		return this.config.unifii.appSecret;
	}

	private setStorageItem(key: string, value: string | null) {

		if (!value) {
			this.storage.removeItem(key);
		} else {
			this.storage.setItem(key, value);
		}
	}

}
