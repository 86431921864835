import { AfterViewInit, ChangeDetectorRef, Component, TemplateRef, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Client, FieldType, FormData, FormDataClient, FormStyle, PublishedContent } from '@unifii/sdk';

import { ContextProvider, DataSourceLoader, ModalService, RuntimeDefinition, RuntimeDefinitionAdapter, SourceConfig, SourceConfigCollection, ThemeProvider, ToastService, fieldIterator } from '@unifii/library/common';
import { DebugValidation, FieldHelperFunctions, FormConfiguration, FormDebugger, FormFunctions, FormRevisionStorage, FormSettings, amendFormData } from '@unifii/library/smart-forms';
import { EmpFormUploader, InputFormSettings, PrintConfig, PrintFormSelectStyleModalComponent, SubmitArgs } from '@unifii/library/smart-forms/input';

import { SCDataService, SCFakeLoader, ShowcaseRepositoryService, UsAPIContentClient } from '../../services';

interface CommonFormInfo {
	id?: string | number;
	identifier?: string;
	label?: string;
	name?: string;
}

@Component({
	selector: 'sc-show-form',
	templateUrl: './show-form.html',
	providers: [
		{ provide: FormSettings, useClass: InputFormSettings },
		FormDebugger,
		{ provide: ContextProvider, useExisting: FormDebugger },
	],
})
export class ShowFormComponent implements AfterViewInit {

	@ViewChild('formOutlet', { read: ViewContainerRef }) private formOutletRef: ViewContainerRef;
	@ViewChild('formTemplate', { read: TemplateRef }) private formTemplateRef: TemplateRef<HTMLElement>;

	protected readonly styleOptions = [
		{ label: 'Summary', value: FormStyle.Summary },
		{ label: 'Full form', value: FormStyle.FullForm },
	];

	protected readonly validationOptions = [
		{ label: 'On', value: DebugValidation.On },
		{ label: 'Off', value: DebugValidation.Off },
		{ label: 'Exclude required', value: DebugValidation.ExcludeRequired },
	];

	protected showSettings = false;
	protected useApi = true;
	protected edited: boolean;
	protected print: boolean;
	protected config: FormConfiguration = {};
	protected printConfig: PrintConfig | null;
	protected formData: FormData | null;
	protected definition: RuntimeDefinition | null;
	protected forms: CommonFormInfo[];
	protected roles: string[] = [];
	protected disabled: boolean;
	protected addCancel = true;
	protected addSubmit: boolean;
	protected formStyle = FormStyle.FullForm;
	protected formDebugger = inject(FormDebugger);

	private client = inject(Client);
	private usContent = inject(UsAPIContentClient);
	private content = inject(PublishedContent);
	private repository = inject(ShowcaseRepositoryService);
	private toast = inject(ToastService);
	private dataService = inject(SCDataService);
	private cd = inject(ChangeDetectorRef);
	private modalService = inject(ModalService);
	private runtimeDefinitionAdapter = inject(RuntimeDefinitionAdapter);
	private formSettings = inject(FormSettings);
	private contextProvider = inject(ContextProvider);
	private themeProvider = inject(ThemeProvider);
	private formRevisionStorage = inject(FormRevisionStorage, { optional: true });
	private route = inject(ActivatedRoute);

	protected set formId(v: number | null) {
		if (v === this.repository.formId) {
			return;
		}

		this.repository.formId = v;

		void this.initialize();
	}

	protected get formId(): number | null {
		return this.repository.formId;
	}

	async ngAfterViewInit() {
		this.themeProvider.theme = { formStyle: this.formStyle };

		this.formSettings.dataLoaderFactory.registerNamed('fake', (sourceConfig: SourceConfig): DataSourceLoader =>
			new SCFakeLoader(sourceConfig as SourceConfigCollection, this.dataService),
		);

		this.formSettings.dataSourceMinSearchLength = this.repository.minSearchLength;

		this.print = !!this.formSettings.print;

		await this.initialize();
	}

	protected async pasteDefinition() {
		try {
			const raw = await navigator.clipboard.readText();
			const parsed = JSON.parse(raw);

			this.updateTemplate(await this.runtimeDefinitionAdapter.transform(parsed));
		} catch (e) {
			this.toast.error('Invalid Definition JSON');
		}
	}

	protected async pasteFormData() {
		try {
			const raw = await navigator.clipboard.readText();
			const data = JSON.parse(raw) as FormData;

			if (this.formRevisionStorage) {
				console.log('FormRevisionStorage - preload revisions start...');
				await this.formRevisionStorage.loadRevisionsByFormData(data);
				console.log('FormRevisionStorage - preload revisions completed...');
			}
			this.formData = data;
		} catch (e) {
			console.error(e);
			this.toast.error('Invalid FormData JSON');
		}
	}

	protected formLabel(info: CommonFormInfo): string {
		return `${info.id} - ${info.name ?? info.label}`;
	}

	protected async printForm() {

		if (!this.definition || !this.formData || !this.formSettings.uploader) {
			return;
		}

		const summary = await this.modalService.openFit(PrintFormSelectStyleModalComponent, null);

		if (summary !== undefined) {
			this.printConfig = {
				definition: this.definition,
				data: this.formData,
				uploader: this.formSettings.uploader,
				summary,
			};
		}
	}

	protected onSubmit(args: SubmitArgs) {

		if (!this.definition) {
			return;
		}

		this.finalize(args);
	}

	protected onCancel() {
		this.toast.info('Cancel requested!');
	}
	
	protected updateCancelLabel() {
		this.config.optionalCancelButtonLabel = this.addCancel ? 'Cancel' : undefined;
	}

	protected updateSubmitLabel() {
		this.config.optionalSubmitButtonLabel = this.addSubmit ? 'Submit' : undefined;
	}

	protected async updateStyleCustomProperty(v: FormStyle) {
		this.themeProvider.theme = { formStyle: v };
		const def = await this.getDefinition(this.formId);

		this.updateTemplate(def);
	}

	protected async updatePrint() {
		this.formSettings.print = this.print;
		const def = await this.getDefinition(this.formId);

		this.updateTemplate(def);
	}

	protected async initialize(roles?: string[]) {
		this.edited = false;

		this.updateCancelLabel();
		this.updateSubmitLabel();

		this.forms = await (this.useApi ? this.usContent.getForms() : this.content.queryForms());

		const definition = await this.getDefinition(this.formId);

		this.roles = definition?.roles ?? [];

		this.formDebugger.roles = roles ?? [...this.roles];

		this.updateTemplate(definition);

		this.showSettings = !definition;
	}

	private async getDefinition(id: number | null): Promise<RuntimeDefinition | undefined> {

		try {
			this.repository.formId = id;
			if (!id) {
				return undefined;
			}

			if (this.useApi) {
				return this.modifyDefinition(await this.runtimeDefinitionAdapter.transform(await this.usContent.getForm(`${id}`)));
			}

			const info = this.forms.find((i) => i.id === id);

			if (!info?.identifier) {
				return undefined;
			}

			return this.modifyDefinition(await this.runtimeDefinitionAdapter.transform(await this.content.getForm(info.identifier)));
		} catch (e) {
			console.warn('Failed to load Definition for formId', id);
		}

		return undefined;
	}

	// Hook for testing purpose
	private modifyDefinition(definition: RuntimeDefinition): RuntimeDefinition {

		for (const { field } of fieldIterator(definition.fields)) {
			if (field.type === FieldType.Text && field.isReadOnly) {
				// field.template = FieldTemplate.Hidden;
			}
		}

		return definition;
	}

	private updateTemplate(definition?: RuntimeDefinition) {

		this.formOutletRef.clear();
		this.cd.detectChanges();

		this.formData = {};
		this.definition = definition ?? null;

		if (definition) {
			this.formSettings.uploader = new EmpFormUploader(new FormDataClient(
				this.client,
				{
					projectId: this.repository.projectId,
					bucket: definition.bucket as unknown as string,
					preview: true,
				},
			));
			this.applyAutofillParams(definition);
			this.formData = amendFormData({}, definition);
			this.formOutletRef.createEmbeddedView(this.formTemplateRef);
		}
	}

	private applyAutofillParams(definition: RuntimeDefinition) {

		const autoFillsParamMap = this.route.snapshot.paramMap;
		const autoFills = {} as Record<string, unknown>;

		if (!autoFillsParamMap.keys.length) {
			return;
		}

		for (const k of autoFillsParamMap.keys) {
			autoFills[k] = autoFillsParamMap.get(k);
		}

		FormFunctions.amendDefinitionAutofills(definition, autoFills);
		console.log('Autofill amended form Definition', definition);
	}

	private finalize(args: SubmitArgs) {

		args.done(args.data);
		this.edited = false;

		this.toast.success(`Transition to '${args.data._state} done'`);

		if (this.keepEditingOnNext()) {
			this.toast.info('Stay open on "Next" action');
		}
	}

	// Stay on this page to allow the User to fill the form 'Next' Section
	private keepEditingOnNext() {

		// Triggered transition, match 'Next' action keyword
		if (!this.definition || this.formData?._action !== 'Next') {
			return false;
		}

		// Triggered transition, to find target State
		const sections = this.definition.fields.filter((f) =>
			f.type === FieldType.Section &&
			f.transitions.find((t) => t.source === this.formData?._state) != null,
		);

		// Check at least a section for target state is accessible by current user
		const user = this.contextProvider.get().user;
		const match = sections.find((s) => FieldHelperFunctions.areRolesMatching(user?.roles, s.roles));

		return match != null;
	}

}
