<sc-page-content identifier="steppers">

	<ng-container editorInputs>
		<uf-radio [options]="orientationOptions" [(value)]="orientation"
			(valueChange)="orientationChange();valueChanges();" class="col-6 col-2-md" label="Orientation" columns="1"
			valueProperty="value" />
		<uf-radio [options]="validationOptions" [(value)]="validation"
			(valueChange)="toggleValidation(); valueChanges();" class="col-6 col-2-md" label="Validation" columns="1"
			valueProperty="value" />
		<uf-radio [options]="labelOptions" [disabled]="labelsInputDisabled" [(value)]="showLabels"
			(valueChange)="valueChanges();" class="col-6 col-2-md" label="Labels" columns="1" valueProperty="value" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template">
			<uf-stepper *ngIf="showDemo" [formGroup]="control" [orientation]="orientation"
				[showLabels]="showLabels === ''" class="grow" style="background: white;">
				<uf-step [control]="personDetailsControl" label="Personal Details">
					<div formGroupName="personalDetails" content class="uf-grid pad row-gap">
						<uf-text label="First Name" formControlName="firstName" class="col-12" />
						<uf-text label="Surname" formControlName="surname" class="col-12" />
					</div>
				</uf-step>
				<uf-step [control]="contactInfoControl" label="Contact Information">
					<div formGroupName="contactInfo" content class="uf-grid pad row-gap">
						<uf-phone label="Phone" formControlName="phone" class="col-12" />
						<uf-email label="Email" formControlName="email" class="col-12" />
					</div>
				</uf-step>
				<uf-step [control]="confirmationControl" label="Confirm Change">
					<div content formGroupName="confirmation" class="uf-grid pad row-gap">
						<uf-checkbox label="I confirm that the changes are correct" formControlName="confirm"
							class="col-12" />
					</div>
					<div actions>
						<button (click)="valueChanges()" actions class="uf-button" type="button">
							Reset
						</button>
					</div>
				</uf-step>
			</uf-stepper>
		</sc-code-editor-expander>
	</ng-container>

</sc-page-content>