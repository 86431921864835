import { Component, OnInit } from '@angular/core';
import { Option } from '@unifii/sdk';

import { Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-grid.html',
	styleUrls: ['./show-grid.less'],
})
export class ShowGridComponent implements OnInit {

	readonly sizes = Size;

	// Tables
	readonly tableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly tableInfo = [
		{ name: 'uf-grid', type: 'base', description: 'Sets children width and spaces them accordingly' },
		{ name: 'pad-lg', type: 'modifier', description: 'Adds 2rem padding to container' },
		{ name: 'pad', type: 'modifier', description: 'Adds 1rem padding to container' },
		{ name: 'pad-sm', type: 'modifier', description: 'Adds 0.5rem padding to container' },
		{ name: 'pad-xs', type: 'modifier', description: 'Adds 0.25rem padding to container' },
		{ name: 'gaps-lg', type: 'modifier', description: 'Adds 2rem space between columns' },
		{ name: 'gaps', type: 'modifier', description: 'Adds 1rem space between columns' },
		{ name: 'gaps-sm', type: 'modifier', description: 'Adds 0.5rem space between columns' },
		{ name: 'gaps-xs', type: 'modifier', description: 'Adds 0.25rem space between columns' },
	];
	readonly breakPointTableConfig: ColumnInfo[] = [
		{ identifier: 'breakpoints', name: 'Breakpoints' },
		{ identifier: 'class', name: 'Class prefix' },
		{ identifier: 'cols', name: '# of cols' },
	];
	readonly breakPointTableInfo = [
		{ breakpoints: 'Small (<576px)', class: 'col-sm-', cols: '12' },
		{ breakpoints: 'Medium (≥768px)', class: 'col-md-', cols: '12' },
		{ breakpoints: 'Small (≥992px)', class: 'col-lg-', cols: '12' },
		{ breakpoints: 'Small (≥1200px)', class: 'col-xl-', cols: '12' },
		{ breakpoints: 'Small (≥1584px)', class: 'col-max-', cols: '12' },
	];

	// Options
	readonly spacingOptions: Option[] = [
		{ identifier: '', name: 'Gaps (default)' },
		{ identifier: 'condensed', name: 'Condensed' },
	];
	readonly rowGapOptions: Option[] = [
		{ identifier: '', name: 'Unset' },
		{ identifier: 'row-gap-lg', name: 'Large' },
		{ identifier: 'row-gap', name: 'Medium (default)' },
		{ identifier: 'row-gap-sm', name: 'Small' },
		{ identifier: 'row-gap-xs', name: 'Extra small' },
	];
	readonly columnGapOptions: Option[] = [
		{ identifier: '', name: 'Unset' },
		{ identifier: 'column-gap-lg', name: 'Large' },
		{ identifier: 'column-gap', name: 'Medium (default)' },
		{ identifier: 'column-gap-sm', name: 'Small' },
		{ identifier: 'column-gap-xs', name: 'Extra small' },
	];
	readonly paddingOptions: Option[] = [
		{ identifier: '', name: 'None (default)' },
		{ identifier: 'pad-lg', name: 'Large' },
		{ identifier: 'pad', name: 'Medium' },
		{ identifier: 'pad-sm', name: 'Small' },
		{ identifier: 'pad-xs', name: 'Extra Small' },
	];

	// Demo columns
	readonly smallScreenColumns = this.getColumns(4, 'sm');
	readonly mediumScreenColumns = this.getColumns(8, 'md');
	readonly largeScreenColumns = this.getColumns(16, 'lg');

	spacing = '';
	columnGap = '';
	padding = '';
	rowGap = '';
	template: string;
	cssClasses: string[] = [];

	private templateSource = `
		<div class="uf-grid #replace">
			<div class="col-12">col-1</div>
		</div>
		`;

	ngOnInit() {
		this.modifierChange();
	}

	modifierChange() {

		this.cssClasses = [this.spacing, this.columnGap, this.padding, this.rowGap];
		this.template = this.templateSource.replace('#replace', this.cssClasses.join(' '));
	}

	private getColumns(total: number, screenSize: string): { className: string; name: string }[] {

		const length = (total / 2) + 1;

		return Array.from({ length }, (v, index) => index + (total / 2))
			.reverse()
			.reduce<{ className: string; name: string }[]>((list, index) => {

				const prefix = 'col-';
				const remainer = total - index;
				const suffix = screenSize ? `-${screenSize}` : '';

				if (remainer > 0) {
					list.push({
						className: prefix + remainer + suffix,
						name: '' + remainer,
					});
				}

				list.push({
					className: prefix + index + suffix,
					name: '' + index,
				});

				return list;

			}, []);

	}

}
